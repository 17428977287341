import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import ButtonLoading from '../../../components/ButtonLoading';
import CustomModal from '../../../components/CustomModal';
import Input from '../../../components/shared/Input';
import TextArea from '../../../components/shared/TextArea';
import { useModifierEtudiantCertificationMutation, useSupprimerEtudiantCertificationMutation } from '../../../redux/slices/etudiants/certificationSlice';
import { toastAlertSuccess } from '../../../utils/toastAlerts';

const EditCertificaton = ({ show, close, certification, refetchCertifications }) => {
    const [loading, setLoading] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const { register, handleSubmit, reset, formState: errors } = useForm({
        defaultValues: {
            name: certification.name,
            lien_certification: certification.lien_certification,
            description: certification.description
        },
        values: {
            name: certification.name,
            lien_certification: certification.lien_certification,
            description: certification.description
        }
    });

    // console.log({ certification });

    const [modifierCertification] = useModifierEtudiantCertificationMutation()
    const [supprimerCertification] = useSupprimerEtudiantCertificationMutation()

    const editCertification = (data) => {
        setLoading(true)
        modifierCertification({ payload: data, certification_id: certification.id })
            .then((res) => {
                console.log({ res });
                if (res.data.success) {
                    toastAlertSuccess("Certification modifée")
                    close()
                    refetchCertifications()
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const supprimerCertificationFunc = () => {
        setIsDeleting(true)
        supprimerCertification(certification.id)
            .then((res) => {
                console.log({ res });
                if (res.data.success) {
                    toastAlertSuccess("Certification supprimée")
                    close()
                    refetchCertifications()
                }
            })
            .finally(() => {
                setIsDeleting(false)
            })
    }

    return (
        <CustomModal
            modalTitle="Modifier une certification"
            show={show}
            handleClose={() => {
                close()
                reset()
            }}
        >
            <form
                onSubmit={handleSubmit(editCertification)}
            >
                <Input
                    type="text"
                    placeholder="Ex: Responsive web design"
                    label="Nom certification"
                    register={{ ...register('name') }}
                    error={errors.name}
                    required
                    htmlFor="name"
                    minLength="5"
                    maxLength="90"
                    name="name"
                />
                <Input
                    type="url"
                    placeholder="Ex: https://www.sololearn.com/Certificate/1234-1234567"
                    label="Lien certification"
                    html="lien_certification"
                    // pattern="https://www.*"
                    register={{ ...register('lien_certification') }}
                    error={errors.lien_certification}
                />
                <TextArea
                    minLength="10"
                    maxLength="500"
                    placeholder="Description"
                    label="Description"
                    htmlFor="description"
                    register={{ ...register('description') }}
                    error={errors.description}
                />
                <ButtonLoading
                    loading={loading}
                    type="submit"
                    text="Modifier"
                    className="btn-apply w-100 mt-3"
                />
                <ButtonLoading
                    onClick={supprimerCertificationFunc}
                    type="button"
                    loading={isDeleting}
                    text="Supprimer"
                    className="btn-danger w-100 mt-3"
                />
            </form>
        </CustomModal>
    )
}

export default EditCertificaton