import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  ENTREPRISE_HOME_PAGE_PATH,
  ETABLISSEMENT_HOME_PAGE_PATH,
  ETUDIANT_HOME_PAGE_PATH,
  REGISTER_ETABLISSEMENT_PATH,
  REGISTER_ETUDIANT_PATH,
} from "../../constants/routes_path";
import logo from "../../image/logo.png";
import { useLoginMutation } from "../../redux/slices/authSlice/authApiSlice";
import { setCredentials } from "../../redux/slices/authSlice/authSlice";
import { toastAlertError } from "../../utils/toastAlerts";
import "./login.css";
import ButtonLoading from '../../components/ButtonLoading';
import TitleH2 from "../../components/shared/TitleH2";

const Login = () => {
  // Initial states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false)

  // Refs
  const userRef = useRef();
  const errRef = useRef();

  const navigate = useNavigate();

  const [login] = useLoginMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    userRef?.current?.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const redirectFunction = (data) => {
    return (
      data?.type === "etudiant" && navigate(ETUDIANT_HOME_PAGE_PATH),
      data?.type === "etablissement" && navigate(ETABLISSEMENT_HOME_PAGE_PATH),
      data?.type === "entreprise" && navigate(ENTREPRISE_HOME_PAGE_PATH)

    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true)
    try {
      const userData = await login({ email, password }).unwrap();
      localStorage.setItem("access_token", userData?.token);

      localStorage.setItem("user", JSON.stringify({ ...userData?.user, etablissement: userData?.etablissement }));
      userData?.user?.type === "etablissement" &&
        localStorage.setItem(
          "etablissementInfos",
          JSON.stringify(userData?.etablissement[0])
        );
      dispatch(setCredentials(userData));
      setEmail("");
      setPassword("");
      // !*************** userData **********************
      // console.log({ userData });
      redirectFunction(userData?.user);
    } catch (error) {
      if (!error?.status) {
        setErrMsg("Auccune reponse du serveur");
      } else if (error?.status === 401) {
        setErrMsg(error?.data?.message);
        toastAlertError(error?.data?.message);
      } else {
        setErrMsg("Echec connexion ");
        toastAlertError("Echec connexion");
      }
      errRef?.current?.focus();
    }
    setLoading(false)
  };

  return (
    <div className="auth-form p-2 mt-5">
      <form onSubmit={handleSubmit}>
        <div className="text-center mt-5 mb-3">
          {/* <img src={logo} alt="" className="taille-img mb-3" /> */}
          <h1 style={{ fontSize: '20px', fontWeight: 700, lineHeight: '30px' }}>
            Bienvenue sur 🤝 <br /><span className='logo-pane'>
              <span className='logo-green'>Bakeli </span>
              <span className="logo-orange">Carriere</span>
            </span></h1>
          <p>Connectez-vous</p>
        </div>
        {/* <label>Email</label> */}
          <input
          className="input-form"
          placeholder="Email"
            type="text"
            autoFocus
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {/* <p className="errorMsg">{errMsg}</p> */}
        {/* <label>Mot de passe</label> */}
          <input
          className="input-form"
          placeholder="Mot de passe"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* <p className="errorMsg">{errMsg}</p> */}
        <div className="mt-3">
            <ButtonLoading
              loading={loading}
              text="Connecter"
              disabled={email?.length === 0 || password?.length === 0 || loading}
            className="btn-apply p-2"
          />
          </div>
        <p className="mt-3 text-center">
            Inscrivez vous en tant qu'{" "}
            <span
              onClick={() => navigate(REGISTER_ETUDIANT_PATH)}
              className="link"
            >
              étudiant
            </span>{" "}
            ou{" "}
            <span
              onClick={() => navigate(REGISTER_ETABLISSEMENT_PATH)}
              className="link"
            >
              établissement
            </span>
          </p>
        </form>
    </div>
  );
};

export default Login;
