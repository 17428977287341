import React from "react";
import CandidaturesEtudiants from "../../components/CandidaturesEtudiants";
// import Main from "../../layouts/Main/Main";
import { useGetAppliedOffresQuery } from "../../redux/slices/offres/offresSlice";
import { SpinnerLoaderSearch } from "../../utils/spinnersLoader";
import { dateFormatter } from "../../utils/timeFormatters";
import FadeTransition from "../LandingPage/components/Fadetransition";

const MesCandidatures = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const isAuthenticated = localStorage.getItem("access_token");
  //   Authenticated user applied offers query
  const { data: appliedJobs, isLoading: loadingCandidatures } =
    useGetAppliedOffresQuery(user?.id, {
      skip: !isAuthenticated,
    });

  // console.log({ appliedJobs });

  return (
    // <Main>
    <FadeTransition in={true}>
      <div className="p-0">
        <div className="row">
          {/* {console.log("applied offers", appliedJobs?.candidatures)} */}
          <div className="col-xl">
            {loadingCandidatures ? (
              <div className="d-flex align-items-center justify-content-center mt-2">
                <SpinnerLoaderSearch />
              </div>
            ) : appliedJobs?.count >= 1 ? (
              appliedJobs?.candidatures?.map((candidature, index) => {

                // console.log(candidature?.domaine.entreprises?.[0].logo);

                const image = `${process.env.REACT_APP_BASE_URL}entreprisesLogos/${candidature?.entreprise?.logo}`

                return <CandidaturesEtudiants
                  titre={candidature?.offre?.titre ?? `Candidature spontanée en ${candidature?.domaine?.name}`}
                  image={image}
                  entreprise={candidature?.entreprise?.user?.name}
                  type={candidature?.offre?.offre_type}
                  location={candidature?.offre?.entreprise?.user?.location ?? candidature?.domaine?.entreprise?.user?.location}
                  datePublication={dateFormatter(
                    candidature?.offre?.created_at
                  )}
                  statusCandidature={candidature?.status_candidature}
                  isAbleToApply={
                    appliedJobs?.candidatures?.some(
                      (item) => item?.offre_id === candidature?.offre?.id
                    )
                      ? false
                      : user?.type === "etablissement" ||
                        user?.type === "entreprise"
                        ? false
                        : true
                  }
                  isNotEtudiant={
                    user?.type === "etablissement" ||
                      user?.type === "entreprise"
                      ? true
                      : false
                  }
                />
              })
            ) : (
              <p>Aucune candidatures pour le moment.</p>
            )}
          </div>
        </div>
      </div>
    </FadeTransition>
    // </Main>
  );
};

export default MesCandidatures;
