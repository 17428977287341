import React, { cloneElement } from 'react'
import { MdAdd } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import ResourceDataMapperWithEmptyMessage from '../../../components/shared/ResourceDataMapperWithEmptyMessage'
import { DETAILS_OFFRES_CANDIDATURE_PATH, EDIT_OFFRE_PATH } from '../../../constants/routes_path'
import { useEntreprise } from '../../../hooks/useEntreprise'
import { setSelectedOffre } from '../../../redux/reducers/offreReducer'
import { setSelectedOffreCandidature } from '../../../redux/reducers/offresCandidature'
import { useGetOffresByEntrepriseQuery } from '../../../redux/slices/entreprises/entreprisesSlice'
import EntrepriseOffreCard from '../components/EntrepriseOffreCard'
import TitleH2 from '../../../components/shared/TitleH2'

const MesOffresListe = () => {
    const { entreprise } = useEntreprise()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { data: offres = [], isLoading } = useGetOffresByEntrepriseQuery({ id_entreprise: entreprise?.id })

    const navigateToCandidatureDetails = (offre) => {
        dispatch(setSelectedOffreCandidature(offre))
        // !redirection
        navigate(DETAILS_OFFRES_CANDIDATURE_PATH)
    }

    const editOffre = (offre) => {
        dispatch(setSelectedOffre(offre))
        return navigate(EDIT_OFFRE_PATH)
    }

    /**
     *! on clone EntrepriseOffreCard pour pouvoir lui passer une props supplementaire : navigateToCandidatureDetails
     */
    const ClonedEntrepriseOffreCard = ({ offre }) => cloneElement(<EntrepriseOffreCard offre={offre} />, { navigateToCandidatureDetails, editOffre })

    return (
        <div className='row'>
            <div className='d-flex justify-content-between mt-2'>
                <TitleH2>Liste des offres</TitleH2>
                <Link className='btn bg-gradient-dark text-light fw-bold' to={"add"}>
                    <MdAdd /> Ajouter
                </Link>
            </div>
            <ResourceDataMapperWithEmptyMessage
                isLoading={isLoading}
                resourceItem={ClonedEntrepriseOffreCard}
                resourceName="offre"
                resourceData={offres}
                emptyMessage="Pas encore d'offres"
            />
        </div>
    )
}

export default MesOffresListe