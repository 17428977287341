import React from 'react'
import Section from '../components/Section'
import LandingPageContainer from '../components/LandingPageContainer'
import SectionH2 from '../components/SectionH2'
import LandingInput from '../components/LandingInput'
import aita from '../assets/img/aita.png'
import LandingBtn from '../components/LandingBtn'

const ContactezNous = () => {
    return (
        <Section>
            <LandingPageContainer>
                <div className='laptop_bg_image p-4'>
                    <SectionH2 styles={{ color: 'white' }}>Contactez-nous</SectionH2>
                    <div className='row'>
                        <div style={{ height: '100% !important' }} className='col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 p-0 p-lg-2'>
                            <h4 className='text-white'>Contactez-nous</h4>
                            <p className='text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam euturpis
                                molestie, dictum est a, mattis tellus.Sed dignissim, metus.</p>
                            <form className='bg-white p-3 rounded-2 mt-3'>
                                <div className='d-flex gap-2'>
                                    <LandingInput label="Prenom" type="text" htmlFor="prenom" />
                                    <LandingInput label="Nom" type="text" htmlFor="nom" />
                                </div>
                                <LandingInput label="Email" type="mail" htmlFor="nom" />
                                <LandingInput label="Telephone" type="phone" htmlFor="nom" />
                                <div class="">
                                    <label for="floatingTextarea">Message</label>
                                    <textarea class="form-control" placeholder="" id="floatingTextarea"></textarea>
                                </div>
                                <div className='d-flex justify-content-end mt-3'>
                                    <LandingBtn className='bg-color-orange px-4 rounded'>
                                        Envoyer
                                    </LandingBtn>
                                </div>
                            </form>
                        </div>
                        <div className='col-lg-6 order-1 order-lg-2 p-0 p-lg-2'>
                            <img src={aita} alt='Contactez-nous' className='img-fluid w-100' />
                        </div>
                    </div>
                </div>
            </LandingPageContainer>
        </Section >
    )
}

export default ContactezNous