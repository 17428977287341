import React, { cloneElement, useEffect } from 'react'
import { GridList } from 'react-flexible-list'
import OffreCardItem from '../components/OffreCardItem'
import { useNavigate } from 'react-router-dom'
import { useGetAllOffresQuery } from '../../../redux/slices/offres/offresSlice'
import { SpinnerLoaderSearch } from '../../../utils/spinnersLoader'
import PaginatedItems from '../../../components/shared/PaginationItems'
import SearchFilter from '../../../components/SearchFilter'
import TitleH2 from '../../../components/shared/TitleH2'
import { resetOffreId, setOffreId } from '../../../redux/reducers/offreReducer'
import { useDispatch } from 'react-redux'
import FadeTransition from '../components/Fadetransition'
import { LANDINGPAGE_OFFRES_PATH } from '../../../constants/routes_path'

const ListOffres = ({ currentItems }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const navigateToDetails = ({ offre_id }) => {
        dispatch(setOffreId(offre_id))
        return navigate(LANDINGPAGE_OFFRES_PATH + '/' + offre_id)
    }
    const ClonedOffreCardItem = ({ offre }) => cloneElement(<OffreCardItem offre={offre} />, {
        navigateToDetails
    })

    return <FadeTransition in={true}>
        <GridList
            resourceData={currentItems}
            resourceItem={ClonedOffreCardItem}
            resourceName="offre"
            cardWidth={300}
            emptyComponent={() => <span>Pas d'offres</span>}
        />
    </FadeTransition>
}

const LandingPageOffresPages = () => {

    const [searchItem, setSearchItem] = React.useState("");
    const dispatch = useDispatch()

    const {
        data: offres,
        isLoading,
        isSuccess,
        isFetching,
        currentData,
        // refetch,
    } = useGetAllOffresQuery(searchItem);

    useEffect(() => {
        dispatch(resetOffreId())
    }, [])

    // Reset all states
    const resetStates = () => {
        setSearchItem("");
    };

    return (
        <>
            <SearchFilter
                inputState={searchItem}
                inputSetState={setSearchItem}
                resetState={resetStates}
            />
            <div className="mt-3">
                {searchItem && <TitleH2>
                    {!isFetching ? offres?.count : '...'} Résultats pour <strong style={{ color: '#009688' }}>{searchItem}</strong>
                </TitleH2>}
            </div>
            <div className='mt-3'>
                {isLoading || (isFetching && !currentData) ? (
                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <SpinnerLoaderSearch />
                    </div>
                ) : isSuccess ? (
                    offres?.count >= 1 ? (
                        <PaginatedItems
                            itemsPerPage={10}
                            ressourceData={offres?.data}
                        >
                            <ListOffres />
                        </PaginatedItems>
                    ) : (
                        <p className="text-center mt-3">Aucune offres pour le moment.</p>
                    )
                ) : (
                    <p className="text-center mt-3">Une erreur s'est produite lors du chargement</p>
                )}
            </div>
        </>
    )
}

export default LandingPageOffresPages