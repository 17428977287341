import React from 'react'

const LandingBtn = ({ className, children, onClick }) => {
    return (
        <button
            onClick={onClick}
            className={`landing-btn ${className}`}
        >
            {children}
        </button>
    )
}

export default LandingBtn