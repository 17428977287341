import React from 'react'
import PaginatedItems from '../../../components/shared/PaginationItems'
import ListeEtudiant from '../offres/ListeEtudiant'

const CandidatsListe = ({ ressourceData = [], className }) => {
    return (
        <div className={className ?? `col-md-6 col-12`}>
            <h6 className='fs-6 fw-bold'>Liste des candidats</h6>
            <PaginatedItems
                itemsPerPage={3}
                ressourceData={ressourceData}
            >
                <ListeEtudiant />
            </PaginatedItems>
        </div>
    )
}

export default CandidatsListe