import React from 'react'
import './landingpage.css'
import Banniere from './components/Banniere'
import ContextSection from './sections/ContextSection'
import LesDifferentsActeurs from './sections/LesDifferentsActeurs'
import ObjectifsSection from './sections/ObjectifsSection'
import AvantagesSection from './sections/AvantagesSection'
import LesChiffresSection from './sections/LesChiffresSection'
import Footer from './sections/Footer'
import ContactezNous from './sections/ContactezNous'
import OffresSection from './sections/OffresSection'

const LandingPage = () => {
    return (
        <div>
            <Banniere />
            <OffresSection />
            <ContextSection />
            <LesDifferentsActeurs />
            <ObjectifsSection />
            <AvantagesSection />
            <LesChiffresSection />
            <ContactezNous />
            <Footer />
        </div>
    )
}

export default LandingPage