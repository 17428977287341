import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import { useUserId } from '../hooks/useUserId';
import { useAjouterMotivationMutation } from '../redux/slices/etudiants/motivationSlice';

const AddMotivation = ({ cancel }) => {
    const { userId } = useUserId()
    const formSchema = Yup.object().shape({
        motivations: Yup.string().required("Obligatoire"),
    });
    const [addMotivation] = useAjouterMotivationMutation()
    // functions to build form returned by useForm() hook
    const formOptions = { resolver: yupResolver(formSchema) };
    const { register, handleSubmit, formState: errors } = useForm(formOptions);
    const [loading, setLoading] = useState(false)

    const submit = (data) => {
        setLoading(true)
        data.user_id = userId
        addMotivation(data)
            .then((res) => {
                if (res.status >= 200) {
                    cancel()
                }
            })
            .finally(() => {
                setLoading(false)
            })
        console.log(data);
    }

    return (
        <div className='mb-4 white-bg px-2 py-3 mx-auto formation-card'>
            <form onSubmit={handleSubmit(submit)}>
                <textarea
                    {...register("motivations")}
                    className={`form-control ${errors.motivations && "is-invalid"}`}
                    type="text"
                    placeholder="Motivation"
                />
                <div className='d-flex gap-2 mt-3'>
                    <button onClick={cancel} type="button" className='btn btn-danger'>Annuler</button>
                    {/* <ButtonLoading loading={loading} text="Ajouter" className="btn-success" /> */}
                    <button type="submit" className='btn btn-success d-flex justify-content-center'>

                        {
                            !loading ? <span>Ajouter</span> :
                                <div style={{ width: '20px', height: '20px' }} className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                        }
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AddMotivation