import React, { useState } from 'react'
import TextArea from '../../../components/shared/TextArea'
import ButtonLoading from '../../../components/ButtonLoading'
import { useForm } from 'react-hook-form';
import { useCandidatureSpontaneeMutation } from '../../../redux/slices/candidature/candidatureSlce';
import { useGetEntrepriseDomaineQuery } from '../../../redux/slices/entreprises/domainesSlice';
import CustomModal from '../../../components/CustomModal';
import Select from '../../../components/shared/Select';
import { useUserId } from '../../../hooks/useUserId';
import { toastAlertError, toastAlertSuccess } from '../../../utils/toastAlerts';

const CandidatureSpontane = ({ show, close, entreprise_id }) => {
    const [isAdding, setIsAdding] = useState(false)
    const { userId } = useUserId()
    const { register, handleSubmit, setError, reset, formState: { errors } } = useForm();
    const [candidaterSpontanement] = useCandidatureSpontaneeMutation()
    const { data = { count: 0, data: [] } } = useGetEntrepriseDomaineQuery(entreprise_id)

    const selectOptions = data.data.map(option => ({ value: option.id, label: option.name }))

    const candidaterSpontanementFunc = (data) => {
        // console.log({ data });
        if (!data.domaine_id) {
            return setError('domaine_id', { type: 'custom', message: 'Domaine obligatoire' });
        }
        setIsAdding(true)
        candidaterSpontanement({ motivation: data.motivation, user_id: userId, domaine_id: data.domaine_id, entreprise_id })
            .unwrap()
            .then(res => {
                if (res.success) {
                    toastAlertSuccess("Candidature spontanée réussie")
                    reset()
                    close()
                }
            }).catch(err => {
                toastAlertError(err.data.message)
            }).finally(() => {
                setIsAdding(false)
            })
    }

    return (
        <CustomModal
            modalTitle="Candidature spontanée"
            show={show}
            handleClose={() => {
                close()
                reset()
            }}
        >
            <form
                onSubmit={handleSubmit(candidaterSpontanementFunc)}
            >
                <Select
                    required
                    htmlFor="domaine"
                    label="Choisir le domaine"
                    emptyMessage="Pas de domaines"
                    selectOptions={selectOptions}
                    register={{ ...register('domaine_id') }}
                    errors={errors}
                    msgError={errors.domaine_id?.message}
                />
                <TextArea
                    minLength="10"
                    maxLength="500"
                    placeholder="Motivation"
                    label="Motivation"
                    htmlFor="motivation"
                    register={{ ...register('motivation') }}
                    error={errors.motivation}
                />
                <ButtonLoading
                    loading={isAdding}
                    text="Ajouter"
                    className="btn-apply w-100 mt-3"
                />
            </form>
        </CustomModal>
    )
}

export default CandidatureSpontane