import React from 'react'
import { BiPhone } from 'react-icons/bi'
import { MdOutlineEmail } from 'react-icons/md'
import { Link } from 'react-router-dom';
// import { STUDENT_DETAILS_PATH } from '../constants/routes_path';

const EtudiantListItem = ({ etudiant }) => {
    // console.log({ etudiant: etudiant });
    return (
        <Link to={`etudiant/${etudiant.user_id}/details`}>
            <div style={{ backgroundColor: 'white', boxShadow: '0 4px 8px rgba(118,118,120,0.2)' }} className='row my-3 align-items-center gap-2 py-3 px-2'>
                <div className='col-sm-1 col-12 pe-sm-2'>
                    <div style={{ width: '55px', height: '55px', backgroundColor: 'rgba(15,20,25,0.3)', borderRadius: '50px', boxShadow: '0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2)' }}>
                        <img
                            // style={{ boxShadow: '0 4px 8px rgba(118,118,120,0.2)' }}
                            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmYiIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBmaWxsPSIjRjZGNkY5IiBkPSJNMCAwaDEyOHYxMjhIMHoiLz48cGF0aCBmaWxsPSIjQkRCREMwIiBkPSJNMTYgMTE2YzAtMTYuNTY5IDEzLjQzMS0zMCAzMC0zMGgzNmMxNi41NjkgMCAzMCAxMy40MzEgMzAgMzB2MjQuODM3YzAgMTYuNTY5LTEzLjQzMSAzMC0zMCAzMEg0NmMtMTYuNTY5IDAtMzAtMTMuNDMxLTMwLTMwVjExNlptNzYtNjRjMCAxNS40NjQtMTIuNTM2IDI4LTI4IDI4UzM2IDY3LjQ2NCAzNiA1MnMxMi41MzYtMjggMjgtMjggMjggMTIuNTM2IDI4IDI4WiIvPjwvc3ZnPgo="
                            alt="Candidat"
                            // onerror={"https://img.myloview.com/stickers/default-avatar-profile-icon-vector-social-media-user-image-700-205124837.jpg"}
                            className=" w-100 rounded-circle"
                        />
                    </div>
                </div>
                <div className='col-sm-10 col-12 ps-sm-2'>
                    <h2
                        style={{ lineHeight: '26px', fontSize: '16px' }}
                        className='mb-1'>
                        {etudiant.user.name}
                        <span
                            style={{ borderRadius: '3px', backgroundColor: '#4dc942', fontSize: '11px', lineHeight: '14px' }}
                            className='p-1 text-light ms-3'
                        >
                            {etudiant.etablissement.raison_sociale}
                        </span></h2>
                    <p
                        style={{ color: '#717173', fontSize: '13px', lineHeight: '24px' }}
                        className='m-0'><BiPhone />
                        <span className='ms-1'>{etudiant.user.phone}</span>
                    </p>
                    <p
                        style={{ color: '#717173', fontSize: '13px', lineHeight: '24px' }}
                        className='fs-6 m-0'>
                        <MdOutlineEmail /><span className='ms-1'>
                            {etudiant.user.email}
                        </span>
                    </p>
                </div>
            </div>
        </Link>
    )
}

export default EtudiantListItem