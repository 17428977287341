import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import ButtonLoading from '../../../components/ButtonLoading';
import CustomModal from '../../../components/CustomModal';
import Input from '../../../components/shared/Input'
import TextArea from '../../../components/shared/TextArea';
import { useUserId } from '../../../hooks/useUserId';
import axios from 'axios';
import { ADD_CERTIFICATION_API_ROUTE } from '../../../constants/api_endpoints';
import { toastAlertError, toastAlertSuccess } from '../../../utils/toastAlerts';

const AddCertification = ({ show, close, refetchCertifications }) => {
    const [loading, setLoading] = useState(false)
    const { userId } = useUserId()
    const { register, handleSubmit, reset, formState: errors } = useForm();

    const addCertification = (data) => {
        const formData = new FormData()
        formData.append("name", data.name);
        formData.append("description", data?.description);
        formData.append("lien_certification", data.lien_certification);
        formData.append("user_id", userId);

        for (let i = 0; i < data.url.length; i++) {
            formData.append("url[]", data.url[i]);
        }

        setLoading(true)
        axios.post(process.env.REACT_APP_API_URL + ADD_CERTIFICATION_API_ROUTE, formData).then(res => {
            if (res.status === 201) {
                refetchCertifications()
                toastAlertSuccess("Certification ajoutée")
            }
            else {
                toastAlertError('Une erreur est survenue')
            }
        })
            .finally(() => {
                setLoading(false)
                close()
            })
    }

    return (
        <CustomModal
            modalTitle="Ajouter une certification"
            show={show}
            handleClose={() => {
                close()
                reset()
            }}
        >
            <form
                onSubmit={handleSubmit(addCertification)}
            >
                <Input
                    type="text"
                    placeholder="Ex: Responsive web design"
                    label="Nom certification"
                    register={{ ...register('name') }}
                    error={errors.name}
                    required
                    htmlFor="name"
                    minLength="5"
                    maxLength="90"
                    name="name"
                />
                <Input
                    type="url"
                    placeholder="Ex: https://www.sololearn.com/Certificate/1234-1234567"
                    label="Lien certification"
                    html="lien_certification"
                    register={{ ...register('lien_certification') }}
                    error={errors.lien_certification}
                />
                <Input
                    type="file"
                    multiple
                    accept=".jpg, .jpeg, .png"
                    label="Choisir image"
                    htmlFor="url"
                    register={{ ...register('url') }}
                    error={errors.url}
                />
                <TextArea
                    minLength="10"
                    maxLength="500"
                    placeholder="Description"
                    label="Description"
                    htmlFor="description"
                    register={{ ...register('description') }}
                    error={errors.description}
                />
                <ButtonLoading
                    loading={loading}
                    text="Ajouter"
                    className="btn-apply w-100 mt-3"
                />
            </form>
        </CustomModal>
    )
}

export default AddCertification