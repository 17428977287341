import React from "react";
// import EntreprisesCards from "../../components/EntreprisesCards";
import PaginatedItems from "../../components/shared/PaginationItems";
import { useGetAllEntreprisesQuery } from "../../redux/slices/entreprises/entreprisesSlice";
import { SpinnerLoaderSearch } from "../../utils/spinnersLoader";
import EntrepriseDataMap from "./EntrepriseDataMap";
import TitleH2 from "../../components/shared/TitleH2";
import FadeTransition from "../LandingPage/components/Fadetransition";

const ListeEntreprises = () => {
  const { data: entreprises, isLoading } = useGetAllEntreprisesQuery();
  console.log({ entreprises });
  return (
    // <Main>
    <div className="p-0">
      <FadeTransition in={true}>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center mt-2">
          <SpinnerLoaderSearch />
        </div>
      ) : entreprises?.count >= 1 ? (

          <>
            <TitleH2>Liste des entreprises</TitleH2>
        <PaginatedItems
          itemsPerPage={7}
          ressourceData={entreprises?.data}
        >
          <EntrepriseDataMap />
        </PaginatedItems>
          </>
      ) : (
        <p className="text-center">Aucune entreprises pour le moment.</p>
      )}
      </FadeTransition>
    </div>
  );
};

export default ListeEntreprises;
