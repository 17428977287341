import {
  REGISTER_ETABLISSEMENT_API_ROUTE,
  REGISTER_ETUDIANT_API_ROUTE,
} from "../../../constants/api_endpoints";
import { apiSlice } from "../apiSlice/apiSlice";

export const registerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    registerEtudiant: builder.mutation({
      query: (payload) => ({
        url: REGISTER_ETUDIANT_API_ROUTE,
        method: "POST",
        body: payload,
        // headers: {
        //   "Content-type": "application/json; charset=UTF-8",
        // },
      }),
    }),

    registerEtablissement: builder.mutation({
      query: (payload) => ({
        url: REGISTER_ETABLISSEMENT_API_ROUTE,
        method: "POST",
        body: payload,
        // headers: {
        //   "Content-type": "application/json; charset=UTF-8",
        // },
      }),
    }),
  }),
});

export const { useRegisterEtudiantMutation, useRegisterEtablissementMutation } =
  registerApiSlice;
