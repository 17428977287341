import Login from "../auth/Login/Login";
import RegisterEtablissement from "../auth/Register/RegisterEtablissement";
import RegisterEtudiant from "../auth/Register/RegisterEtudiant";
import {
  AJOUT_ETUDIANTS_PATH,
  DETAILS_OFFRES_CANDIDATURE_FROM_ENTREPRISE_DETAILS_PATH,
  DETAILS_OFFRES_CANDIDATURE_PATH,
  EDIT_OFFRE_PATH,
  ENTREPRISES_PATH,
  ENTREPRISE_CANDIDATURES_PAGE_PATH,
  ENTREPRISE_CANDIDATURE_DETAILS_PAGE_PATH,
  ENTREPRISE_ENTITIES_PAGE_PATH,
  ENTREPRISE_HOME_PAGE_PATH,
  ENTREPRISE_MES_OFFRES_PATH,
  ETABLISSEMENT_DETAILS_ENTREPRISE_PATH,
  ETABLISSEMENT_HOME_PAGE_PATH,
  ETUDIANTS_PATH,
  ETUDIANT_HOME_PAGE_PATH,
  LANDINGPAGE_OFFRES_DETAILS_PATH,
  LANDINGPAGE_OFFRES_PATH,
  LANDINGPAGE_PATH,
  LOGIN_PATH,
  MES_CANDIDATURES,
  MON_COMPTE,
  MY_PROFILE_PAGE,
  OFFRES_PATH,
  //   OFFRE_DETAILS_PATH,
  REGISTER_ETABLISSEMENT_PATH,
  REGISTER_ETUDIANT_PATH,
} from "../constants/routes_path";
import AjoutEtudiant from "../pages/AjoutEtudiant/AjoutEtudiant";
import EntitiesHomePage from "../pages/Entreprises/entities/EntitiesHomePage";
import EntitiesListeEtablissment from "../pages/Entreprises/entities/EntitiesListeEtablissment";
import EntrepriseHomePage from "../pages/Entreprises/EntrepriseHomePage";
// import Dashboard from "../pages/Dashboard/Dashboard";
import ListeEntreprises from "../pages/Entreprises/ListeEntreprises";
import EntrepriseMesOffres from "../pages/Entreprises/offres/EntrepriseMesOffres";
import MesOffresListe from "../pages/Entreprises/offres/MesOffresListe";
import OffresCandidatureDetails from "../pages/Entreprises/offres/OffresCandidatureDetails";
import EtablisssementHomePage from "../pages/Etablissement/EtablisssementHomePage";
import EtudiantHomePage from "../pages/Etudiant/EtudiantHomePage";
import ListeEtudiants from "../pages/ListeEtudiants/ListeEtudiants";
import MesCandidatures from "../pages/MesCandidatures/MesCandidatures";
// import DetailsOffre from "../pages/Offres/DetailsOffre";
import Offres from "../pages/Offres/Offres";
import Profile from "../pages/Profile/Profile";
import DetailsEntreprise from '../pages/Entreprises/DetailsEntreprise';
import EntrepriseDash from "../pages/Etablissement/entreprise/EntrepriseDash";
import EtudiantEntrepriseDash from "../pages/Etudiant/entreprise/EtudiantEntrepriseDash";
import AjoutOffre from "../pages/Entreprises/offres/AjoutOffrePage";
import EditOffrePage from "../pages/Entreprises/offres/EditOffrePage";
import DetailsEtudiantPage from "../pages/ListeEtudiants/DetailsEtudiantPage";
import DetailsEntrepriseEtudiantPage from "../pages/Etudiant/entreprise/DetailsEntrepriseEtudiantPage";
import DetailsOffreEtudiantPage from "../pages/Etudiant/offres/DetailsOffreEtudiantPage";
import EntrepriseDashboard from "../pages/Entreprises/EntrepriseDashboard";
import EntrepriseCandidaturePage from "../pages/Entreprises/EntrepriseCandidaturePage";
import DetailsCandidature from "../pages/Entreprises/DetailsCandidature";
import EntrepriseCandidatureHomePage from "../pages/Entreprises/EntrepriseCandidatureHomePage";
import Page404 from "../pages/LandingPage/components/Page404";
import MonCompteHomePage from "../pages/Entreprises/monCompte/MonCompteHomePage";
import { Outlet } from "react-router-dom";
import LandingPageOffresPages from "../pages/LandingPage/pages/LandingPageOffresPages";
import LandingPageDetailsOffre from "../pages/LandingPage/pages/LandingPageDetailsOffre";
// import BreadCrumps from "../pages/LandingPage/components/shared/BreadCrump";
import Section from "../pages/LandingPage/components/Section";
import LandingPageContainer from "../pages/LandingPage/components/LandingPageContainer";
import LandingPage from "../pages/LandingPage/LandingPage";
import BreadCrumps from "../pages/LandingPage/components/shared/BreadCrump";

/**
 * * PRIVATE ROUTES *************************
 */
export const privateRoutes = [
  {
    path: ETUDIANT_HOME_PAGE_PATH,
    element: <EtudiantHomePage />
  },
  {
    path: ETABLISSEMENT_HOME_PAGE_PATH,
    element: <EtablisssementHomePage />
  },
  {
    path: ENTREPRISE_HOME_PAGE_PATH,
    element: <EntrepriseHomePage />
  },
  {
    path: '/etudiant' + MY_PROFILE_PAGE,
    element: <Profile />,
  },
];

/**
 * * ETUDIANTS ROUTES ***********************
 */
export const etudiantRoutes = [
  {
    path: '',
    // index: true,
    element: <Offres />,
  },
  {
    path: ENTREPRISES_PATH,
    element: <EtudiantEntrepriseDash />,
    children: [
      {
        path: '',
        element: <ListeEntreprises />
      },
      {
        path: ":id/details",
        element: <DetailsEntrepriseEtudiantPage />,
        // children: [
        // ]
      },
    ]
  },
  {
    path: MES_CANDIDATURES,
    element: <MesCandidatures />,
  },
  {
    path: DETAILS_OFFRES_CANDIDATURE_FROM_ENTREPRISE_DETAILS_PATH,
    element: <DetailsOffreEtudiantPage />
  },
  {
    path: ETUDIANTS_PATH,
    element: <ListeEtudiants />
  }
  // {
  //   path: MY_PROFILE_PAGE,
  //   element: <Profile />,
  // },
]

/**
 * * ETABLISSEMENTS ROUTES ***********************
 */
export const etablissementRoutes = [
  {
    path: '',
    element: <ListeEtudiants />
  },
  // {
  //   path: '',
  //   element: <EtablissementDash />
  // },
  {
    path: OFFRES_PATH,
    element: <Offres />,
  },
  {
    path: ENTREPRISES_PATH,
    element: <EntrepriseDash />,
    children: [
      {
        path: '',
        element: <ListeEntreprises />
      },
      {
        path: ":id/details",
        element: <DetailsEntrepriseEtudiantPage />
      }
    ]
  },
  {
    path: MES_CANDIDATURES,
    element: <MesCandidatures />,
  },
  {
    path: AJOUT_ETUDIANTS_PATH,
    element: <AjoutEtudiant />
  },
  {
    path: ETABLISSEMENT_DETAILS_ENTREPRISE_PATH,
    element: <DetailsEntreprise />
  }
]

/**
 * * ENTREPRISE ROUTES ************************
 */
export const entrepriseRoutes = [
  {
    path: '',
    element: <EntrepriseDashboard />
  },
  {
    path: ENTREPRISE_ENTITIES_PAGE_PATH,
    element: <EntitiesHomePage />,
    children: [
      // {
      //   path: '',
      //   element: <EntitiesListeEntreprise />
      // },
      {
        path: '',
        element: <EntitiesListeEtablissment />
      },
      {
        path: ':id/details',
        element: <DetailsEntreprise />
      }
    ]
  },
  {
    path: ENTREPRISE_MES_OFFRES_PATH,
    element: <EntrepriseMesOffres />,
    children: [
      {
        path: '',
        element: <MesOffresListe />
      },
      {
        path: 'add',
        element: <AjoutOffre />
      },
      {
        path: EDIT_OFFRE_PATH,
        element: <EditOffrePage />
      }
      ,
      {
        path: DETAILS_OFFRES_CANDIDATURE_PATH,
        element: <OffresCandidatureDetails />
      },
      {
        path: ":userId/candidat",
        element: <DetailsEtudiantPage />
      }
    ]
  },
  {
    path: ENTREPRISE_CANDIDATURES_PAGE_PATH,
    element: <EntrepriseCandidatureHomePage />,
    children: [
      {
        path: '',
        element: <EntrepriseCandidaturePage />
      },
      {
        path: ENTREPRISE_CANDIDATURE_DETAILS_PAGE_PATH,
        element: <DetailsCandidature />
      },
      {
        path: ':userId/candidat',
        element: <DetailsEtudiantPage />
      }
    ]
  },
  {
    path: MON_COMPTE,
    element: <MonCompteHomePage />
  }
]

/**
 * * PUBLIC ROUTES *****************************
 */
export const publicRoutes = [
  // {
  //   path: '',
  //   element: <LandingPage />
  // },
  {
    path: LOGIN_PATH,
    element: <Login />,
  },

  {
    path: REGISTER_ETUDIANT_PATH,
    element: <RegisterEtudiant />,
  },

  {
    path: REGISTER_ETABLISSEMENT_PATH,
    element: <RegisterEtablissement />,
  },
  {
    path: '*',
    element: <Page404 />
  }
];

export const landingPageRoutes = [
  // {
  //   path: '',
  //   element: <LandingPage />
  // },
  {
    path: LANDINGPAGE_PATH,
    breadcrumb: 'Accueil',
    element: <LandingPage />
  },
  {
    path: LANDINGPAGE_OFFRES_PATH,
    element: <Section>
      <LandingPageContainer maxWidth={1300}>
        <BreadCrumps />
        <div className="mt-3">
          <Outlet />
        </div>
      </LandingPageContainer>
    </Section>,
    children: [
      {
        path: '',
        element: <LandingPageOffresPages />
      },
      {
        path: LANDINGPAGE_OFFRES_DETAILS_PATH,
        breadcrumb: 'Détails',
        element: <LandingPageDetailsOffre />
      },
    ]
  },
  {
    path: '*',
    element: <Page404 />
  },
];

// export const routes = [

//   {
//     path: DASHBOARD_PATH,
//     element: <Dashboard />,
//   },

//   {
//     path: OFFRE_DETAILS_PATH,
//     element: <DetailsOffre />,
//     },

//   {
//     path: ENTREPRISES_PATH,
//     element: <ListeEntreprises />,
//   },

//   {
//     path: OFFRES_BY_ENTREPRISES_PATH,
//     element: <OffresByEntreprise />,
//   },
// ];
