import { createSlice } from "@reduxjs/toolkit";

const etudiantReducer = createSlice({
    name: 'etudiants',
    initialState: { etudiantsListe: [], allEtudiants: [], isSearchingStudent: false, currentEtudiantSearch: '' },
    reducers: {
        setEtudiantsListe: (state, { payload }) => {
            state.etudiantsListe = payload
            state.allEtudiants = payload
        },
        resetEtudiantsListe: (state) => {
            state.etudiantsListe = {}
            state.allEtudiants = {}
        },
        filterEtudiant: (state, { payload }) => {
            state.isSearchingStudent = true
            state.currentEtudiantSearch = payload
            if (payload === '') {
                state.isSearchingStudent = false
                state.etudiantsListe = state.allEtudiants
            } else {
                state.etudiantsListe = state.allEtudiants.filter(etudiant => {
                    console.log(etudiant.user.name.toLowerCase() === payload.toLowerCase());
                    return etudiant.user.name.toLowerCase().includes(payload.toLowerCase())
                })
            }
        },

    }
})

export const { setEtudiantsListe, resetEtudiantsListe, filterEtudiant } = etudiantReducer.actions

export default etudiantReducer.reducer