import React from 'react'

export const ClipPathTitle = ({ children }) => {
    return <h3 className='clip_path_title'>{children}</h3>
}
export const ClipPathList = ({ children }) => {
    return <li className='clip_path_list'>{children}</li>
}

const ClipPath = ({ children, className }) => {
    return (
        <div className={`clip_path ${className}`}>
            {children}
        </div>
    )
}

export default ClipPath