import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import CustomModal from "../../components/CustomModal";
import { toastAlertSuccess } from "../../utils/toastAlerts";

// Register the plugin
registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const CvModal = ({
  title,
  show,
  close,
  setCvFile,
  user,
  accessToken,
  refetch,
  setIsEditing,
}) => {
  return (
    <CustomModal modalTitle={title ?? "Mon Cv"} show={show} handleClose={close} size="" >
      <div className="text-center">
        {/* TODO: chercher comment mettre le nom du fichier par defaut */}
        <FilePond
          acceptedFileTypes={["application/pdf"]}
          maxFileSize="2MB"
          allowFileSizeValidation={true}
          onupdatefiles={(fileItems) => {
            setCvFile(fileItems.map((fileItem) => fileItem.file));
          }}
          labelIdle='Glisser & deposer votre CV ou <span className="filepond--label-action">Parcourrir</span>'
          credits={null}
          server={{
            process: `${process.env.REACT_APP_API_URL}resume/${user?.id}`,
            remove: null,
            revert: null,
            fetch: false,
            load: false,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }}
          instantUpload={true}
          name={"cv"}
          onprocessfile={() => {
            refetch();
            toastAlertSuccess("CV Ajouté.");
            close();
          }}
          onremovefile={() => {
            setIsEditing(false);
            setCvFile(null);
          }}
          labelFileProcessing="Transfert en cours"
          labelTapToCancel="Appuyer pour annuler"
          labelFileProcessingAborted="Transfert annulé"
          labelTapToRetry="Appuyer pour recommencer"
          labelMaxFileSize="La taille maximale est de 2 Mo"
          labelMaxFileSizeExceeded="Fichier trop volumineux"
        />
        <div className="d-flex flex-column">
          <p style={{ fontSize: "14px", fontWeight: "500", color: "#000" }}>
            Taille maximale: 2 Mo
          </p>
          <p style={{ fontSize: "14px", fontWeight: "500", color: "#000" }}>
            Format: PDF
          </p>
        </div>
      </div>
    </CustomModal>
  );
};

export default CvModal;
