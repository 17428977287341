import React from 'react'

const FlexBetween = ({ children, className }) => {
    return (
        <div className={`d-flex flex-column flex-md-row justify-content-between gap-2 ${className}`}>
            {children}
        </div>
    )
}

export default FlexBetween