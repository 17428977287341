import React from 'react'
import { Route, Routes } from 'react-router-dom'
// import CandidatureCardItem from '../../components/CandidatureCardItem'
// import { MY_PROFILE_PAGE } from '../../constants/routes_path'
import Navbar from '../../layouts/Navbar/Navbar'
// import { useGetOffresKpisQuery } from '../../redux/slices/offres/offresSlice'
// import { etudiantRoutes } from '../../routes/routes'
// import { useUserId } from '../../hooks/useUserId'
// import ResourceDataMap from '../../components/shared/ResourceDataMap'
// import { ETUDIANT_HOME_PAGE_PATH, MES_CANDIDATURES } from '../../constants/routes_path'
import EtudiantDash from './EtudiantDash'
import { MY_PROFILE_PAGE } from '../../constants/routes_path'
import Profile from '../Profile/Profile'
// import DetailsEntrepriseEtudiantPage from './entreprise/DetailsEntrepriseEtudiantPage'

const EtudiantHomePage = () => {

    return (
        <div>
            <Navbar />
            <div className='container-lg mt-5 px-2'>
                <Routes>
                    <Route path='/*' element={<EtudiantDash />} />
                    {/* <Route path=':id/details' element={<DetailsEntrepriseEtudiantPage />} /> */}
                    <Route path={MY_PROFILE_PAGE} element={<Profile />} />
                </Routes>
            </div>
        </div>
    )
}

export default EtudiantHomePage