import React from 'react'
import { NavLink } from 'react-router-dom';
// import { privateRoutes } from 'routes/navigation/navigationRoutes';
import { landingPageRoutes } from '../../../../routes/routes';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { styled } from 'styled-components';

const BreadCrumpItem = styled(NavLink)`
    text-decoration: none !important;
    text-underline: none !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    border-bottom: 1px solid gray !important;
    color: gray !important;

    &.active{
        color: #f0ad4e !important;
    };
    &:after{
        content: " > "
    };
     &:last-of-type{
        color: rgba(10, 20, 30, .5);
        ext - decoration: none!important;
    };
    &:last-of-type::after{
        content: "";
    }
`

//     .breadcrump - navlink{
//         padding: 10px;
// color: var(--secondary);
// }
// .breadcrump - navlink:hover{
//     color: #003436;
// }
// .breadcrump - navlink::after{
//     content: " /";
// }
// .breadcrump - navlink: last - of - type{
//     color: rgba(10, 20, 30, .5);
//     text - decoration: none!important;
// }
// .breadcrump - navlink: last - of - type::after{
//     content: "";
// }


const BreadCrumps = () => {
    const breadcrumbs = useReactRouterBreadcrumbs(landingPageRoutes);

    // console.log({ breadcrumbs });

    return (
        <div
            // style={{

            // }}
            className='py-1'
        >
            {breadcrumbs.map(({ match, breadcrumb }) => (
                <BreadCrumpItem
                    key={match.pathname}
                    to={match.pathname}
                >
                    {breadcrumb}
                </BreadCrumpItem>
            ))}
        </div>
    );
}

export default BreadCrumps