import React from "react";
import { FaTrashAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";

const SearchFilter = ({
  inputState,
  inputSetState,
  dateState,
  dateSetState,
  resetState,
}) => {
  //   const [timer, setTimer] = React.useState(null);
  //   const inputChanged = (e) => {
  //     inputSetState(e.target.value);

  //     clearTimeout(timer);

  //     const newTimer = setTimeout(() => {
  //       refetch();
  //     }, 5000);

  //     setTimer(newTimer);
  //   };

  return (
    <div style={{ boxShadow: '0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2) !important' }} className="search_filter_container sticky-top m-0 p-0 px-2">
      {/* {console.log("current date", currentDate)} */}
      <div className="row py-0">
        <div className="col-10 d-flex align-items-center">
          <FiSearch className="mx-1 search_filter_icon" icon="search" />
          <input
            type="text"
            value={inputState}
            onChange={(e) => inputSetState(e.target.value)}
            placeholder="Que recherchez-vous ?"
            className="search_input mx-1 form-control"
          />
        </div>

        <div className="col-2 d-flex align-items-center justify-content-end m-0 pl-0 ps-1">
          <FaTrashAlt
            className="mx-1 reset_filter_icon"
            icon="trash-2"
            onClick={() => resetState()}
          />
          {/* <Tooltip title="Reinitialiser le filtre" placement="top">
            </Tooltip> */}
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
