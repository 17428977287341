import React, { cloneElement } from 'react'
import Wysiwyg from '../../../components/shared/Wysiwyg';
import { styled } from 'styled-components'
import { GridList } from 'react-flexible-list'
import OffreCardItem from '../components/OffreCardItem'
import LandingPageEntrepriseBanniere from '../components/LandingPageEntrepriseBanniere'
import { useNavigate } from 'react-router-dom'
import { LANDINGPAGE_OFFRES_PATH } from '../../../constants/routes_path'
import { useGetAllOffresQuery, useGetOffreByIdQuery } from '../../../redux/slices/offres/offresSlice'
import { setOffreId } from '../../../redux/reducers/offreReducer';
import { useDispatch, useSelector } from 'react-redux';
import SearchFilter from '../../../components/SearchFilter';
import TitleH2 from '../../../components/shared/TitleH2';
import FadeTransition from '../components/Fadetransition';
import ProgressLoading from '../components/shared/ProgressLoading';
import { SpinnerLoaderSearch } from '../../../utils/spinnersLoader';

const JobListsPane = styled.div`
    flex:1;
    padding: 20px .5rem;
    position: relative;
    height: 100vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scroll-padding-top: 50%;
    &::-webkit-scrollbar {
        width: 4px;
    };
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px transparent; 
        border-radius: 10px;
    };
    &::-webkit-scrollbar-thumb {
        height: 50px;
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px transparent; 
    }
`
const CompanyJobDescriptionPane = styled.div`
    // width: 100%
    flex:2;
`
const OffreBodyPane = styled.div`
    padding: 1.5rem 15px;
`
const OffreTitlePane = styled.h2`
font-weight: 600;
font-size: 28px;
line-height: 150%;
color: #0d0140;
`
const OffreDescriptionPane = styled.div`
    height: 60vh;
    border-right: 1px solid rgba(20,40,50, .4);
    overflow-y: scroll;
    padding-right: 10px;

    &::-webkit-scrollbar {
    width: 3px;
    };
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        border-radius: 10px;
    };
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    }
`
const ActionsPane = styled.div`
    padding:0px 20px;
`
const ParagraphePane = styled.div`
    // all: revert !important;
    font-size: 16px;
    font-weight: 600;
    height: 100%;
`

const LandingPageDetailsOffre = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchItem, setSearchItem] = React.useState("");

    const { offreId } = useSelector(state => state.offres)

    const {
        data: offres,
        isLoading: isOffresLoading,
        isFetching,
        // refetch,
    } = useGetAllOffresQuery(searchItem);

    const {
        data: detailsOffre = {
            data: {
                titre: '',
                description: '',
                entreprise: {
                    user: { name: '' },
                    secteur: '', effectif: 0
                }
            }
        },
        isLoading,
        // refetch,
        isFetching: isDetailsOffreFetching
    } = useGetOffreByIdQuery(offreId)

    const navigateToDetails = ({ offre_id, refId }) => {
        dispatch(setOffreId(offre_id))
        // refetch(offre_id)
        console.log(document.getElementById(refId).parentNode.offsetHeight);
        console.log(document.getElementById(refId).offsetTop);
        document.getElementById(refId).scrollIntoView()
        // document.getElementById(refId).scrollTo = 0
        return navigate(LANDINGPAGE_OFFRES_PATH + '/' + offre_id, { state: { data: offre_id } })
    }

    const ClonedOffreCardItem = ({ offre }) => cloneElement(<OffreCardItem offre={offre} />, {
        navigateToDetails
    })

    const resetStates = () => {
        setSearchItem("");
    };

    return (
        <FadeTransition
            // key={detailsOffre?.data.titre}
            in={true}>
            <div className='d-flex flex-wrap'>
                <div className='row w-100'>
                    <div className='col-4'>
                        <div className='mb-2'>
                            <SearchFilter
                                inputState={searchItem}
                                inputSetState={setSearchItem}
                                resetState={resetStates}
                            />
                            <div className="mt-3">
                                {searchItem && <TitleH2>{!isFetching ? offres?.count : '...'} Résultats pour <strong style={{ color: '#009688' }}>{searchItem}</strong></TitleH2>}
                            </div>
                        </div>
                        <JobListsPane>
                            {
                                isOffresLoading ?
                                    <SpinnerLoaderSearch />
                                    :
                                    <GridList
                                resourceData={offres?.data}
                                resourceItem={ClonedOffreCardItem}
                                resourceName="offre"
                                cardWidth={200}
                                emptyComponent={() => <span>Pas d'offres</span>}
                            />
                            }
                        </JobListsPane>
                    </div>
                    <CompanyJobDescriptionPane className='col-8'>
                        <LandingPageEntrepriseBanniere
                            isLoading={isLoading}
                            name={detailsOffre?.data.entreprise.user.name}
                            effectif={detailsOffre?.data.entreprise.effectif}
                            secteur={detailsOffre?.data.entreprise.secteur}
                            banniere={`${process.env.REACT_APP_BASE_URL}entreprisesBannieres/${detailsOffre?.data.entreprise?.banniere}`}
                        />
                        <OffreBodyPane>
                            <OffreTitlePane>
                                {isDetailsOffreFetching ? <ProgressLoading /> :
                                    <FadeTransition key={detailsOffre?.data.titre} in={true}>
                                        {detailsOffre?.data.titre}
                                    </FadeTransition>
                                }
                            </OffreTitlePane>
                            <div className='d-flex flex-wrap flex-lg-nowrap px-0'>
                                <OffreDescriptionPane className='col-sm-9 col-12'>
                                    <ParagraphePane>
                                        {
                                            isDetailsOffreFetching ?
                                                <ProgressLoading mt="mt-4" /> :
                                                <FadeTransition key={detailsOffre?.data.description} in={true}>
                                                    <Wysiwyg contenu={detailsOffre?.data.description} />
                                                </FadeTransition>
                                        }
                                    </ParagraphePane>
                                </OffreDescriptionPane>
                                <ActionsPane className='col-sm-3 col-12'>
                                    <button
                                        className='btn btn-primary p-2 px-4 text-center'
                                    >
                                        Postuler
                                    </button>
                                </ActionsPane>
                            </div>
                        </OffreBodyPane>
                    </CompanyJobDescriptionPane>
                </div>
            </div>
        </FadeTransition>
    )
}

export default LandingPageDetailsOffre