import React, { cloneElement, useMemo } from 'react'
import DashKpi from './components/DashKpi'
import { GridList } from 'react-flexible-list'
import TitleH2 from '../../components/shared/TitleH2'
import { useGetEntrepriseKpiQuery } from '../../redux/slices/entreprises/entreprisesSlice'
import { useEntreprise } from '../../hooks/useEntreprise'
import { ENTREPRISE_CANDIDATURES_PAGE_PATH, ENTREPRISE_MES_OFFRES_PATH } from '../../constants/routes_path'

const EntrepriseDashboard = () => {
    const { entreprise } = useEntreprise()
    const { data } = useGetEntrepriseKpiQuery({ id_entreprise: entreprise?.id })
    // console.log({ data, entreprise });
    const ClonedDashSkpi = ({ kpi }) => cloneElement(<DashKpi kpi={kpi} />, {})
    const entrepriseKpis = useMemo(() => [
        {
            label: 'Offres',
            number: data?.nbrOffre ?? '...',
            className: 'bg-gradient-dark',
            to: ENTREPRISE_MES_OFFRES_PATH
        },
        {
            label: 'Candidatures',
            number: data?.nbrCandidature ?? '...',
            className: 'bg-gradient-primary',
            to: ENTREPRISE_CANDIDATURES_PAGE_PATH
        },
        {
            label: 'Convoquées',
            number: data?.nbrCandidatConvoques ?? '...',
            className: 'bg-gradient-success',
            to: ENTREPRISE_CANDIDATURES_PAGE_PATH
        },
        {
            label: 'Rejetées',
            number: data?.nbrCandidatureRejetes ?? '...',
            className: 'bg-gradient-warning',
            to: ENTREPRISE_CANDIDATURES_PAGE_PATH
        },
        {
            label: 'Validées',
            number: data?.nbrCandidatureValides ?? '...',
            className: 'bg-gradient-info',
            to: ENTREPRISE_CANDIDATURES_PAGE_PATH
        },
    ], [data?.nbrCandidatConvoques, data?.nbrCandidature, data?.nbrCandidatureRejetes, data?.nbrCandidatureValides, data?.nbrOffre])
    return (
        <div className=''>
            <TitleH2>Dashboard</TitleH2>
            <GridList
                resourceData={entrepriseKpis}
                resourceItem={ClonedDashSkpi}
                resourceName="kpi"
            />
            {/* <DashKpi /> */}
        </div>
    )
}

export default EntrepriseDashboard