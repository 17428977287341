import {
  ADD_OFFRE_BY_ENTREPRISE_API_ROUTE,
  APPLY_TO_OFFRE_API_ROUTE,
  DELETE_OFFRE_BY_ENTREPRISE_API_ROUTE,
  EDIT_OFFRE_BY_ENTREPRISE_API_ROUTE,
  GET_ALL_OFFRES_API_ROUTE,
  GET_APPLIED_OFFRES_API_ROUTE,
  GET_LATEST_OFFRES_API_ROUTE,
  GET_OFFRE_BY_ID_API_ROUTE,
  KPI_ETUDIANT_API_ROUTE,
} from "../../../constants/api_endpoints";
import { apiSlice } from "../apiSlice/apiSlice";

export const offresSlice = apiSlice.injectEndpoints({
  tagTypes: ["Offres"],
  endpoints: (builder) => ({
    getAllOffres: builder.query({
      query: (searchParams) =>
        `${GET_ALL_OFFRES_API_ROUTE}${searchParams !== undefined && searchParams !== null
          ? "?q=" + searchParams
          : ""
        }`,
      providesTags: ["Offres"],
    }),
    getLatestOffres: builder.query({
      query: () => `${GET_LATEST_OFFRES_API_ROUTE}`,
      providesTags: ["Offres"],
    }),
    getOffreById: builder.query({
      query: (id) => `${GET_OFFRE_BY_ID_API_ROUTE}/${id}`,
      providesTags: ["Offres"],
    }),
    getAppliedOffres: builder.query({
      query: (user_id) => `${GET_APPLIED_OFFRES_API_ROUTE}${user_id}`,
      providesTags: ["Offres"],
    }),

    addOffreByEntreprise: builder.mutation({
      query: (data) => {
        return {
          url: ADD_OFFRE_BY_ENTREPRISE_API_ROUTE,
          body: data,
          method: "POST"
        }
      },
      invalidatesTags: ["Offres"]
    }),
    editOffreByEntreprise: builder.mutation({
      query: ({ data, offre_id }) => {
        return {
          url: `${EDIT_OFFRE_BY_ENTREPRISE_API_ROUTE}${offre_id}`,
          body: data,
          method: "POST"
        }
      },
      invalidatesTags: ["Offres"]
    }),
    deleteOffreByEntreprise: builder.mutation({
      query: ({ offre_id }) => {
        return {
          url: `${DELETE_OFFRE_BY_ENTREPRISE_API_ROUTE}${offre_id}`,
          method: "DELETE"
        }
      },
      invalidatesTags: ["Offres"]
    }),

    // getOffresByEntreprise: builder.query({
    //   query: ({ id_entreprise }) => {
    //     console.log({ id_entreprise });
    //     return `${GET_OFFRES_BY_ENTREPRISES_API_ROUTE}${id_entreprise}`
    //   },
    //   keepUnusedDataFor: 60,
    //   providesTags: ["Offres"],
    // }),
    // offres_by_entreprises/id_entreprise

    applyToOffer: builder.mutation({
      query: (args) => {
        const { user_id, offre_id, entreprise_id } = args;
        return {
          url: `${APPLY_TO_OFFRE_API_ROUTE}/${user_id}/${offre_id}`,
          body: { entreprise_id },
          method: "POST",
        };
      },
      invalidatesTags: ["Offres"],
    }),
    getOffresKpis: builder.query({
      query: (user_id) => `${KPI_ETUDIANT_API_ROUTE}${user_id}`,
      providesTags: ["Offres"]
    })
  }),
});

export const {
  useGetAllOffresQuery,
  useGetLatestOffresQuery,
  useGetOffreByIdQuery,
  useApplyToOfferMutation,
  useGetAppliedOffresQuery,
  useGetOffresKpisQuery,
  // useGetOffresByEntrepriseQuery
  useAddOffreByEntrepriseMutation,
  useEditOffreByEntrepriseMutation,
  useDeleteOffreByEntrepriseMutation
} = offresSlice;
