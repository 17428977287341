import React, { useState } from 'react'
import ButtonLoading from '../../../components/ButtonLoading'
import CustomModal from '../../../components/CustomModal'
import { useForm } from 'react-hook-form'
import { useAddMutation, useGetAvailableDomainesQuery } from '../../../redux/slices/entreprises/domainesSlice'
import { useEntreprise } from '../../../hooks/useEntreprise'
import { toastAlertError, toastAlertSuccess } from '../../../utils/toastAlerts'
import CreatableSelect from 'react-select/creatable';

const AddEntrepriseDomaineModal = ({ show, close }) => {
    const [isAdding, setIsAdding] = useState(false)
    const [addEntrepriseDomaine] = useAddMutation()
    const { entreprise } = useEntreprise()
    const { data: availableDomaines } = useGetAvailableDomainesQuery(entreprise?.id)
    const [selectedDomaines, setSelectedDomaines] = useState([])


    const options = availableDomaines?.data?.map(({ id, name }) => ({ label: name, value: id }))

    // console.log({ options });


    const { handleSubmit, reset } = useForm();
    const addEntrepriseDomaines = (data) => {
        const domaines = selectedDomaines.map(({ value }) => value)
        setIsAdding(true)
        addEntrepriseDomaine({ data: { domaines }, id_entreprise: entreprise.id }).then((res) => {
            if (res.data.success) {
                toastAlertSuccess("Domaine ajouté")
                reset()
                close()
            }
        }).catch(err => {
            console.log({ err });
            toastAlertError("Use erreur est survenue lors de l'ajout")
        }).finally(() => {
            setIsAdding(false)
        })
    }

    return (
        <CustomModal
            modalTitle="Ajoutez un domaine"
            show={show}
            handleClose={() => {
                close()
                reset()
            }}
        >
            <form
                onSubmit={handleSubmit(addEntrepriseDomaines)}
            >
                <CreatableSelect
                    required
                    isMulti options={options}
                    onChange={selected => {
                    setSelectedDomaines(selected)
                }} />
                {/* <Input
                    type="text"
                    placeholder="Ex: Design"
                    label="Nom domaine"
                    register={{ ...register('name', { required: 'Champ obligatoire' }) }}
                    error={errors.name?.message}
                    // required
                    htmlFor="name"
                    minLength="3"
                    maxLength="90"
                    name="name"
                />
                <TextArea
                    minLength="10"
                    maxLength="500"
                    placeholder="Description"
                    label="Description"
                    htmlFor="description"
                    register={{ ...register('description', { required: "Champ obligatoire" }) }}
                    error={errors.description?.message}
                /> */}
                <ButtonLoading
                    loading={isAdding}
                    text="Ajouter"
                    className="btn-apply w-100 mt-3"
                />
            </form>
        </CustomModal>
    )
}

export default AddEntrepriseDomaineModal