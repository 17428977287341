import React from "react";
// import logo from "../image/logo.png";
import "./Entreprises.css";

import FeatherIcon from "feather-icons-react";
import TextTruncate from "react-text-truncate";
import { useNavigate } from "react-router-dom";
import { onError } from "../../../utils/onError";
import { useUser } from '../../../hooks/useUser';
import ButtonLoading from "../../../components/ButtonLoading";

const EntreprisesCards = ({ description, logo, name, secteur, banner, id, openCandidatureSpontaneModal }) => {
  // boxShadow: '0px 1px 2px #3ac1b3b7'
  // 0 0 0 1px #3ac1b3b7, 0 2px 3px #3ac1b3b7
  const navigate = useNavigate()
  const user = useUser()

  return (
    // <Link to={`${id}/details`}>
    <div
      // onClick={() => user.type !== "etudiant" ? navigate(`${id}/details`) : null}
      onClick={() => navigate(`${id}/details`)}
      style={{
        boxShadow: '0 0 0 1px #3ac1b3b7, 0 2px 3px #3ac1b3b7',
        cursor: 'pointer'
        // cursor: user.type !== "etudiant" ? 'pointer' : 'default'
      }}
      className="card"
    >
        <div className="shadow-sm">
          <img
            src={banner}
            className="card-img-top"
            alt={name}
            onError={onError}
          />
        </div>
        <div className="card-body position-relative">
          <div style={{ backgroundImage: `url(${logo})` }} className="img-circle">
          </div>
          <div className="card-body-text">
            <h5 className="card-title">{name}</h5>
            <p className="card-text mb-0 d-flex align-items-center">
              <span>
                <FeatherIcon icon="briefcase" size="16" />
              </span>
              <span className="dep-list-profil-entreprise ms-2">
                Secteur: {secteur}
              </span>
            </p>
            <TextTruncate
              line={2}
              element="span"
              truncateText="…"
              text={description}
            />
        </div>

        {user.type === "etudiant" &&
          openCandidatureSpontaneModal && <ButtonLoading
            onClick={(e) => {
              e.stopPropagation()
              openCandidatureSpontaneModal(id)
            }}
            text="Candidature spontanée"
            type="button"
            className="btn-apply"
          />
        }        
      </div>
      </div>
    // </Link>
  );
};

export default EntreprisesCards;
