import React from 'react'

const LesChiffresCardItem = ({ number, text, icon: Icon }) => {
    return (
        <div className='col-sm-3 col-6 p-2'>
            <div
                className='bg-white les_chiffres_card_item shadow rounded-3'>
                <span className=''>{number}</span>
                <p className='chiffre_card_item_text m-0'>{text}</p>
                <Icon size={30} color="#009688" />
            </div>
        </div>
    )
}

export default LesChiffresCardItem