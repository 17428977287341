import React from 'react'

const TypeBadge = ({ text, bgCOlor }) => {
    return (
        <span
            style={{ borderRadius: '3px', backgroundColor: bgCOlor, fontSize: '11px', lineHeight: '14px' }}
            className='p-1 text-light ms-3'
        >
            {text}
        </span>
    )
}

export default TypeBadge