import { ADD_PROJET_API_ROUTE, DELETE_ETUDIANT_PROJET_API_ROUTE, EDIT_ETUDIANT_PROJET_API_ROUTE, GET_ETUDIANT_PROJET_API_ROUTE } from "../../../constants/api_endpoints";
import { apiSlice } from "../apiSlice/apiSlice";


const projetSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        ajouterProjet: builder.mutation({
            query: (formData) => {
                console.log({ payload: formData });
                return {
                    url: `${ADD_PROJET_API_ROUTE}`,
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
                        // "Content-type": "application/json; charset=UTF-8",
                        // "Content-type": "multipart/form-data, boundary=" + Math.random().toString(),
                    },
                    body: formData,
                }
            },
            invalidatesTags: ["Projets"],
        }),

        getEtudiantProjets: builder.query({
            query: (user_id) => `${GET_ETUDIANT_PROJET_API_ROUTE}${user_id}`,
            providesTags: ["Projets"],
        }),

        modifierProjetEtudiant: builder.mutation({
            query: (args) => {
                // console.log({ args });
                const { project_id, payload } = args;
                return {
                    url: `${EDIT_ETUDIANT_PROJET_API_ROUTE}${project_id}`,
                    method: "POST",
                    body: payload,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            invalidatesTags: ["Projets"],
        }),
        supprimerProjetEtudiant: builder.mutation({
            query: ({ projet_id }) => ({
                url: `${DELETE_ETUDIANT_PROJET_API_ROUTE}${projet_id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Projets"],
        }),

        getProjetImages: builder.query({
            query: (user_id) => `${GET_ETUDIANT_PROJET_API_ROUTE}${user_id}`,
            providesTags: ["Projets"],
        }),
    })
})

export const { useAjouterProjetMutation, useGetEtudiantProjetsQuery, useModifierProjetEtudiantMutation, useSupprimerProjetEtudiantMutation } = projetSlice