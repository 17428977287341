import React from 'react'

const Input = ({ label, register, htmlFor, required, type, error, min, ...props }) => {
    return (
        <div className="mb-2">
            <label htmlFor={htmlFor} className="form-label">
                {label}
            </label>
            <input
                min={min}
                type={type}
                className={`form-control ${error && "is-invalid"}`}
                id={htmlFor}
                {...register}
                required={required}
                {...props}
            />
            {
                error && <span className='text-danger fs-6'>{error}</span>
            }
        </div>
    )
}

export default Input