import React, { useState } from 'react'
import ResourceDataMapperWithEmptyMessage from '../../../components/shared/ResourceDataMapperWithEmptyMessage'
import CandidatListItem from '../components/CandidatListItem'
import { cloneElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { ENTREPRISE_HOME_PAGE_PATH, ENTREPRISE_MES_OFFRES_PATH } from '../../../constants/routes_path'
import { useConvoquerMutation, useRejectMutation, useValidateMutation } from '../../../redux/slices/candidature/candidatureSlce'
import CustomModal from '../../../components/CustomModal'
import { useForm } from 'react-hook-form'
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'

const ListeEtudiant = ({ currentItems }) => {
    const formSchema = Yup.object().shape({
        date_entretien: Yup.string().required("Obligatoire")
    });
    // functions to build form returned by useForm() hook
    const formOptions = { resolver: yupResolver(formSchema) };
    const { register, handleSubmit, reset, formState: { errors } } = useForm(formOptions);
    const [validate, { isLoading: isValidating }] = useValidateMutation()
    const [reject, { isLoading: isRejecting }] = useRejectMutation()
    const [convoquer, { isLoading: isConvocation }] = useConvoquerMutation()
    const navigate = useNavigate()
    const [confirmCandidatureModal, setConfirmCandidatureModal] = useState()
    const [selectedCandidatureToConfirm, setSelectedCandidatureToConfirm] = useState(null)

    const navigateToCandidatDetails = (candidat) => {
        // console.log({ candidat });
        return navigate(ENTREPRISE_HOME_PAGE_PATH + '/' + ENTREPRISE_MES_OFFRES_PATH + '/' + candidat.id + '/candidat')
    }
    /**
         * 
         * !ATTTTTTTTTTTTTEEEEEEENNNNNNNNTION CES METHODES NE SONT PAS UTILISEES ICI
         */
    // ! on ouvre le modal pour choir la date de l'entretien
    const confirmCandidatureToOpenModal = (candidature) => {
        console.log('yup');
        setConfirmCandidatureModal(true)
        setSelectedCandidatureToConfirm(candidature)
    }
    const onSubmit = (data) => {
        convoquer('**** candidatureId *****').then(res => {
            console.log({ res });
        }).catch(err => {
            console.log({ err });
        })
    }
    const ClonedCandidatListItem = ({ candidat }) => cloneElement(<CandidatListItem candidat={candidat} />, {
        navigateToCandidatDetails,
        confirmCandidatureToOpenModal,
        isValidating,
        isRejecting,
        isConvocation
    })
    return (
        <>
            {/* ce modal n'est pas utiliser */}
            <CustomModal
                modalTitle="Selectionnez la date de l'entretien"
                show={confirmCandidatureModal}
                handleClose={() => {
                    setConfirmCandidatureModal(false)
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                        type="date"
                        className={`form-control ${errors.date_entretien && "is-invalid"}`}
                        id="name"
                        {...register("date_entretien")}
                    />
                </form>
            </CustomModal>
            <ResourceDataMapperWithEmptyMessage
                resourceData={currentItems}
                resourceItem={ClonedCandidatListItem}
                resourceName="candidat"
                emptyMessage="Pas de candidats"
            />
        </>
    )
}

export default ListeEtudiant