import React from 'react'
import { useNavigate } from 'react-router-dom'

const DashKpi = ({ kpi }) => {
    const { label, number, className, to } = kpi
    const navigate = useNavigate()
    return (
        <div
            onClick={() => navigate(to)}
            style={{
                boxShadow: '#344767',
                height: '100px',
                backgroundColor: '#ffffff',
                padding: 10,
                borderRadius: 10,
                position: 'relative',
                cursor: to ? 'pointer' : 'not-allowed'
            }}
            className='d-flex justify-content-between'
        >
            <div
                style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: 10,
                    boxShadow: 'rgba(0, 0, 0, 0.14) 0px 4px 20px 0px, rgba(64, 64, 64, 0.4) 0px 7px 10px -5px',
                    // top: '-20px',
                    // position: 'absolute',
                    // zIndex: 22
                }}
                className={className}
            >

            </div>
            <div style={{ textAlign: 'right' }}>
                <span style={{ fontWeight: 500 }} className='fs-6'>{label}</span>
                <p className='fw-bold'>{number}</p>
            </div>
        </div>
    )
}

export default DashKpi