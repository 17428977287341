import React from 'react'
import { useSelector } from 'react-redux'
import { MdOutlineLocalOffer, MdOutlinePeopleOutline } from 'react-icons/md'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { dayJsFormat } from '../../../utils/dayJsFormat'
import CandidatsListe from '../components/CandidatsListe'
import NavigateBack from '../../../components/shared/NavigateBack'
import Wysiwyg from '../../../components/shared/Wysiwyg'

const dayjs = require('dayjs')

dayJsFormat()

const OffresCandidatureDetails = () => {
    const { selectedOffreCandidature } = useSelector(state => state.offresCandidatures)
    // console.log({ selectedOffreCandidature });
    return (
        <div>
            {/* <button
                onClick={() => navigate(-1)}
                type="button"
                className='navigate--back'
            >
                <TiArrowBackOutline />
            </button> */}
            <NavigateBack />

            <h3 className='mt-2 fs-4'>Details Offre</h3>

            <div className='bg-white vh-100 p-2 p-sm-3 pt-4 shadow-sm'>
                <div className='row h-100'>
                    <div className='col-md-6 h-100 col-12 d-flex flex-column justify-content-between'>
                        <h4 className='fs-5 fw-bold'>{selectedOffreCandidature?.payload?.titre}</h4>
                        {/* <h6 className='fs-5 text-decoration-underline mt-2'>Description de l'offre</h6> */}
                        <div style={{ height: '100%', overflow: 'auto' }}>
                            <Wysiwyg contenu={selectedOffreCandidature?.payload?.description} />
                        </div>
                        {/* <p className='fs-6'>{selectedOffreCandidature?.payload?.description}</p> */}
                        <div className='d-flex gap-3 mt-3'>
                            <p>
                                <MdOutlineLocalOffer /> <span className='fs-6'>{selectedOffreCandidature?.payload?.offre_type}</span>
                            </p>
                            <p>
                                <MdOutlinePeopleOutline /> <span className='fs-6'>{selectedOffreCandidature?.payload?.candidats.length} candidats</span>
                            </p>
                            <p>
                                <AiOutlineClockCircle /> <span className='fs-6'>il y a {dayjs(selectedOffreCandidature?.payload?.created_at).fromNow(true)}</span>
                            </p>
                        </div>
                    </div>
                    <CandidatsListe
                        ressourceData={selectedOffreCandidature?.payload?.candidats}
                    />
                    {/* <div className='col-md-5 col-12'>
                        <h6 className='fs-6'>Liste des candidats</h6>
                        <PaginatedItems
                            itemsPerPage={3}
                            ressourceData={selectedOffreCandidature?.payload?.candidats}
                        >
                            <ListeEtudiant />
                        </PaginatedItems>
                    </div> */}
                </div>
            </div>

        </div>
    )
}

export default OffresCandidatureDetails