import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import ButtonLoading from '../../../components/ButtonLoading';
import CustomModal from '../../../components/CustomModal';
import Input from '../../../components/shared/Input';
import TextArea from '../../../components/shared/TextArea';
import { useModifierProjetEtudiantMutation, useSupprimerProjetEtudiantMutation } from '../../../redux/slices/etudiants/projetSlice';

const EditAddProjetModal = (({ show, close, projet }) => {
    const [loading, setLoading] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const { register, handleSubmit, reset, formState: errors } = useForm({
        defaultValues: {
            name: projet.name,
            description: projet.description,
            lien_depot: projet?.lien_depot,
            link_project: projet?.link_project
        },
        values: {
            name: projet.name,
            description: projet.description,
            lien_depot: projet?.lien_depot,
            link_project: projet?.link_project
        }
    });
    const [modifierProjet] = useModifierProjetEtudiantMutation()
    const [supprimerProjet] = useSupprimerProjetEtudiantMutation()

    const submitForm = (data) => {
        // console.log({ project_id: projet.id, payload: data });
        setLoading(true)
        modifierProjet({ project_id: projet.id, payload: data }).then((response) => {
            console.log({ response });
        }).catch((error) => {

        })
            .finally(() => {
                setLoading(false)
                close()
            })
    }

    const supprimerProjetFunc = () => {
        setIsDeleting(true)
        supprimerProjet({ projet_id: projet.id }).then(res => {
            console.log({ res });
        })
            .catch(err => {
                console.log({ err });
            })
            .finally(() => {
                setIsDeleting(false)
                close()
            })
    }

    return (
        <CustomModal
            modalTitle="Modifier projet"
            show={show}
            handleClose={() => {
                close()
                reset()
            }}
        >
            <form
                onSubmit={handleSubmit(submitForm)}
            >
                <Input
                    type="text"
                    placeholder="Gestion de budget"
                    label="Nom projet"
                    register={{ ...register('name') }}
                    error={errors.name}
                    required
                    htmlFor="name"
                    minLength="5"
                    maxLength="90"
                />
                <Input
                    type="url"
                    placeholder="www.github.com/lka/gestion-budget"
                    label="Lien dépôt"
                    html="lien_depot"
                    // pattern="https://www.*"
                    register={{ ...register('lien_depot') }}
                    error={errors.lien_depot}
                />
                <Input
                    type="url"
                    placeholder="www.geston-budget.bakeli.tech"
                    label="Lien projet"
                    htmlFor="link_project"
                    register={{ ...register('link_project') }}
                    error={errors.link_project}
                />
                <TextArea
                    minLength="10"
                    maxLength="500"
                    placeholder="Description du projet"
                    label="Description"
                    htmlFor="description"
                    register={{ ...register('description') }}
                    error={errors.description}
                />
                <ButtonLoading
                    loading={loading}
                    text="Modifier"
                    className="btn-apply w-100 mt-3"
                />
                <ButtonLoading
                    onClick={supprimerProjetFunc}
                    type="button"
                    loading={isDeleting}
                    text="Supprimer"
                    className="btn-danger w-100 mt-3"
                />
            </form>
        </CustomModal>
    )
})

export default EditAddProjetModal