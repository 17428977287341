import React from 'react'
import Section from '../components/Section';
import LandingPageContainer from '../components/LandingPageContainer';
import SectionH2 from '../components/SectionH2';
import { FaSchool, FaUsers } from 'react-icons/fa';
import { MdOutlineWork } from 'react-icons/md'
import LesChiffresCardItem from '../components/LesChiffresCardItem';
import { IoMdSchool } from 'react-icons/io';

const nosChiffres = [
    {
        number: '5.000',
        text: "Etudiants",
        icon: IoMdSchool
    },
    {
        number: '1.235',
        text: "Offres",
        icon: MdOutlineWork
    },
    {
        number: '325',
        text: "Entreprises",
        icon: FaUsers
    },
    {
        number: '995',
        text: "Etablissements",
        icon: FaSchool
    },
]

const LesChiffresSection = () => {
    return (
        <Section className='les_chiffres_section'>
            <LandingPageContainer>
                <SectionH2>Les chiffres</SectionH2>
                <div className='row'>
                    {
                        nosChiffres.map(({ number, text, icon }) => <LesChiffresCardItem
                            number={number}
                            text={text}
                            icon={icon}
                        />)
                    }
                </div>
            </LandingPageContainer>
        </Section>
    )
}

export default LesChiffresSection