import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import { AiFillForward } from "react-icons/ai";
import { AiFillMail } from "react-icons/ai";
import { AiFillCloseSquare } from "react-icons/ai";

import Button from "./shared/Button";
import { onError } from "../utils/onError";
import { styled } from "styled-components";
// import "./Entreprises.css";
import Wysiwyg from './shared/Wysiwyg';
import { dayJsFormat } from "../utils/dayJsFormat";
import { HiLocationMarker, HiOutlineClock, HiOutlinePaperClip } from "react-icons/hi";
import { truncateString } from "../utils/truncateString";

const dayjs = require('dayjs')

dayJsFormat()

const CompanyLogoPane = styled.img`
    width: 60px;
    height: 60px;
    object-fit: cover;
    background-position: 50%;
    background-size: 70%;
    background-color: #fff;
    background-repeat: no-repeat;
    border: 3px solid #d6cee4;
    border-radius: 50px;
`
const CardTitlePane = styled.h5`
  color: #000;
  font-family: Montserrat;
  font-size: 20.777px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

const OffresCard = ({
  titre,
  type,
  description,
  created_at,
  date_expiration,
  // datePublication,
  location,
  image,
  entreprise,
  applyFunction,
  isLoading,
  isAbleToApply,
  isNotEtudiant,
  statusCandidature,
}) => {
  return (
    (
      <>
        {
          statusCandidature && <div
            style={{
              // boxShadow: '0 1px -1px 1px rgba(20,30,40,.2), 0 2px 3px rgba(0,0,0,.2)',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
              borderRadius: 0
            }}
            className="card border-0 my-4 bg-white offresCard py-3 px-md-4 px-3"
          >
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div
                className="d-flex gap-4 flex-wrap"
              >
                <CompanyLogoPane
                  // style={{ width: '75px', height: '75px' }}
                  // className="card-img-left"
                  src={image}
                  alt={titre}
                  onError={onError}
                />
                <div className="*****************">
                  <div>
                    <CardTitlePane>{entreprise}</CardTitlePane>
                    <p className="fw-bold">{titre}</p>
                    <div style={{ maxWidth: '500px' }} className="fs-6 mt-3">
                      <Wysiwyg contenu={truncateString(description, 100)} />
                    </div>
                  </div>
                  <p
                    className="fs-6 my-3 text-secondary"
                  >
                    <span>
                      <HiOutlinePaperClip />
                      {type}
                    </span> | {" "}
                    <span>
                      <HiLocationMarker />
                      {location}
                    </span> |{" "}
                    <span>
                      <HiOutlineClock />
                      Il y à {dayjs(created_at).fromNow(true)}
                    </span>
                  </p>
                </div>
              </div>
              <div>
                {
                  isAbleToApply ? (
                    <div className="">
                      {
                        !date_expiration ? <Button
                          className="btn-apply w-full"
                          onClick={applyFunction}
                          isLoading={isLoading}
                        >
                          {isLoading ? "En cours..." : "Postulez"}
                        </Button> : (new Date(date_expiration) >= new Date()) ? <Button
                          className="btn-apply w-full"
                          onClick={applyFunction}
                          isLoading={isLoading}
                        >
                          {isLoading ? "En cours..." : "Postulez"}
                        </Button> : undefined
                      }
                    </div>
                  ) : (
                    !isNotEtudiant && (
                      <span
                        className="d-inline-block text-end fs-6"
                        style={{ color: "#ff9800" }}
                      >
                        Deja Postulée <BsPatchCheckFill />
                      </span>
                    )
                  )
                }

                {
                  statusCandidature === "en_cours" ? (
                    <div class="stepper-wrapper">
                      <div class="stepper-item completed">
                        <div class="step-counter">
                          <AiFillForward />
                        </div>
                        <div class="step-name">Postulé</div>
                      </div>
                      <div class="stepper-item active">
                        <div class="step-counter">...</div>
                        <div class="step-name">En traitement</div>
                      </div>
                      <div class="stepper-item active">
                        <div class="step-counter"></div>
                        <div class="step-name"></div>
                      </div>
                    </div>
                  ) : (
                    <> </>
                  )
                }

                {
                  statusCandidature[0] === "c" ? (
                    <div class="stepper-wrapper">
                      <div class="stepper-item completed">
                        <div class="step-counter">
                          <AiFillForward />
                        </div>
                        <div class="step-name">Postulé</div>
                      </div>
                      <div class="stepper-item completed">
                        <div class="step-counter-orange">
                          <AiFillMail />
                        </div>
                        <div class="step-name">Convoqué en entretien</div>
                      </div>
                      <div class="stepper-item active">
                        <div class="step-counter">...</div>
                        <div class="step-name">En traitement</div>
                      </div>
                    </div>
                  ) : (
                    <> </>
                  )
                }

                {
                  statusCandidature[0] === "r" ? (
                    <div class="stepper-wrapper">
                      <div class="stepper-item completed">
                        <div class="step-counter">
                          <AiFillForward />{" "}
                        </div>
                        <div class="step-name">Postulé</div>
                      </div>
                      <div class="stepper-item completed">
                        <div class="step-counter-orange">
                          <AiFillMail />
                        </div>
                        <div class="step-name">Entretien effectué </div>
                      </div>
                      <div class="stepper-item completed">
                        <div class="step-counter-red">
                          <AiFillCloseSquare />
                        </div>
                        <div class="step-name">Candidature {statusCandidature}</div>
                      </div>
                    </div>
                  ) : (
                    <> </>
                  )
                }
              </div>
            </div>
            {/* <div className="">
              <div className="">

              </div>
            </div> */}
            <div className='mt-2'>
              {
                !date_expiration ? <span className='text-warning fs-6'> Pas de date d'expiration specifiée</span> : new Date(date_expiration) < new Date() ? <span className='fw-bold text-danger'>Offre expirée 🚫</span> : <p className='text-success fs-6'>
                  Disponible jusqu'au {new Date(date_expiration).toLocaleString('fr-Fr', {
                    dateStyle: 'short'
                  })}
                </p>
              }
            </div>
          </div>
        }
      </>
    )
  );
};

export default OffresCard;
