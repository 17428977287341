import React from 'react'
import Section from '../components/Section'
import LandingPageContainer from '../components/LandingPageContainer'
import SectionH2 from '../components/SectionH2'
import SectionParagrapheTitle from '../components/SectionParagrapheTitle'
import solution1 from '../assets/img/solution1.png'
import solution2 from '../assets/img/solution2.png'
import solution3 from '../assets/img/solution3.png'
import solution4 from '../assets/img/solution4.png'
import solution5 from '../assets/img/solution5.png'
import solution6 from '../assets/img/solution6.png'

const images = [solution1, solution2, solution3, solution4, solution5, solution6]

const AvantagesSection = () => {
    return (
        <Section className='bg-white'>
            <LandingPageContainer>
                <SectionH2>Avantages de la solution</SectionH2>
                <SectionParagrapheTitle>
                    Profitez de l'efficacité et de la simplicité offertes par notre plateforme de gestion de stage, qui vous permet de gagner du temps,
                    d'accéder à un large éventail d'opportunités et de maximiser votre succès professionnel
                </SectionParagrapheTitle>
                <div className='row mt-3'>
                    {
                        images.map(src => {
                            return <div className='col-12 col-sm-4 col-md-4 p-1'>
                                <img
                                    className='img-fluid'
                                    src={src}
                                    alt='Avantages Bakeli Carriere'
                                />
                            </div>
                        })
                    }
                </div>
            </LandingPageContainer>
        </Section>
    )
}

export default AvantagesSection