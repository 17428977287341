import React from 'react'
import SectionH2 from '../components/SectionH2'
import Section from '../components/Section'
import SectionParagrapheTitle from '../components/SectionParagrapheTitle'
import ClipPath, { ClipPathList, ClipPathTitle } from '../components/ClipPath'
import LandingPageContainer from '../components/LandingPageContainer'

const ContextSection = () => {
    return (
        <Section>
            <LandingPageContainer>
                <SectionH2>Contexte</SectionH2>
                <SectionParagrapheTitle>
                    En comprenant les contraintes et les aspirations des étudiants d'aujourd'hui, notre plateforme s'engage à répondre à leurs besoins spécifiques
                    en matière de gestion de stage, offrant ainsi une expérience enrichissante et valorisante
                </SectionParagrapheTitle>
                <div className='clip_paths_container'>
                    <ClipPath className="clip_path_orange">
                        <ClipPathTitle>70% de diplômés</ClipPathTitle>
                        <ul>
                            <ClipPathList>Chôment pendant 6 mois après formation</ClipPathList>
                        </ul>
                    </ClipPath>
                    <ClipPath className="clip_path_green">
                        <ClipPathTitle>~ 198 000 étudiants au Sénégal en 2019</ClipPathTitle>
                        <ul>
                            <ClipPathList>30% dans le privé
                            </ClipPathList>
                            <ClipPathList>70% dans le public
                            </ClipPathList>
                        </ul>
                    </ClipPath>
                    <ClipPath className="clip_path_red">
                        <ClipPathTitle>90% des étudiants</ClipPathTitle>
                        <ul>
                            <ClipPathList>N'ont pas de stage
                            </ClipPathList>
                            <ClipPathList>Croissance chaque année du nombre d'étudiants
                            </ClipPathList>
                        </ul>
                    </ClipPath>
                </div>
            </LandingPageContainer>
        </Section>
    )
}

export default ContextSection