import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import TitleH3 from '../../components/shared/TitleH3';
import { MdEmail } from 'react-icons/md';
import { HiLocationMarker } from 'react-icons/hi';
import { BsPhoneFill } from 'react-icons/bs';
import Button from '../../components/shared/Button';
import { AiFillEye } from 'react-icons/ai';
import { dayJsFormat } from '../../utils/dayJsFormat';
import { Badge } from 'react-bootstrap';
import ButtonLoading from '../../components/ButtonLoading';
import { useConvoquerMutation, useGetByIdQuery, useRejectMutation, useValidateMutation } from '../../redux/slices/candidature/candidatureSlce';
import { useNavigate } from 'react-router-dom';
import { ENTREPRISE_CANDIDATURES_PAGE_PATH, ENTREPRISE_CANDIDAT_DETAILS_PAGE_PATH, ENTREPRISE_HOME_PAGE_PATH } from '../../constants/routes_path';
// import { setSelectedCandidature } from '../../redux/reducers/candidatureReducer';
import CustomModal from '../../components/CustomModal';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form';
import { toastAlertError, toastAlertSuccess } from '../../utils/toastAlerts';
import Select from '../../components/shared/Select';
import { typeFormations } from '../../utils/type_entretien';
import InputForm from '../../components/shared/InputForm';

const dayjs = require('dayjs')

dayJsFormat()

const TextProfil = ({ children }) => {
    return <p style={{ fontSize: 12 }} className='m-0 mb-1'>{children}</p>
}
const TitleProfil = ({ children }) => {
    return <h6 style={{ fontSize: 14, fontWeight: 700 }}>{children}</h6>
}

const DetailsCandidature = () => {
    const formSchema = Yup.object().shape({
        date_entretien: Yup.string().required("Obligatoire")
    });
    // functions to build form returned by useForm() hook
    const formOptions = { resolver: yupResolver(formSchema) };
    const { register, handleSubmit, reset, setError, formState: { errors } } = useForm(formOptions);
    // const dispatch = useDispatch()
    const { selectedCandidature, selectedCandidatureId } = useSelector(state => state.candidatures)
    const { data = [] } = useGetByIdQuery({ id_candidature: selectedCandidatureId })
    // dispatch(setSelectedCandidature(data?.data[0]))
    // console.log({ data: data, selectedCandidatureId });
    const [validate, { isLoading: isValidating }] = useValidateMutation()
    const [reject, { isLoading: isRejecting }] = useRejectMutation()
    const [confirmCandidatureModal, setConfirmCandidatureModal] = useState()
    const [convoquer, { isLoading: isConvocation }] = useConvoquerMutation()
    // const [selectedCandidatureToConfirm, setSelectedCandidatureToConfirm] = useState(null)
    const navigate = useNavigate()

    // console.log({ selectedCandidature, data });

    const validateCandidature = (candidatureId) => {
        validate(candidatureId).then(res => {
            toastAlertSuccess('Candidature validée')
        }).catch(err => {
            toastAlertError(err.message)
        })
    }
    const rejectCandidature = (candidatureId) => {
        reject(candidatureId).then(res => {
            toastAlertSuccess('Candidature rejetée')
        }).catch(err => {
            toastAlertError(err.message)
        })
    }
    // ! on ouvre le modal pour choir la date de l'entretien
    const confirmCandidatureToOpenModal = (candidature) => {
        setConfirmCandidatureModal(true)
        // setSelectedCandidatureToConfirm(candidature)
    }
    const onSubmit = (data) => {
        convoquer({ data, id: selectedCandidature.id }).then(res => {
            setConfirmCandidatureModal(false)
            console.log({ res });
            toastAlertSuccess('Candidat convoqué')
            reset({
                date_entretien: '',
                heure_entretien: '',
                lieu_entretien: ''
            })
        }).catch(err => {
            toastAlertError(err.message)
        })
    }
    return (
        <>
            <CustomModal
                modalTitle="Sélectionnez la date de l'entretien"
                show={confirmCandidatureModal}
                handleClose={() => {
                    setConfirmCandidatureModal(false)
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputForm
                        type="date"
                        label="Date entretien"
                        htmlFor="date"
                        required
                        register={{ ...register("date_entretien") }}
                        error={errors.date_entretien?.message}
                    />
                    <InputForm
                        type="date"
                        label="Date limite confirmation"
                        htmlFor="date"
                        required
                        register={{ ...register("date_confirmation") }}
                        error={errors.date_entretien?.message}
                    />
                    {/* {date_confirmation } */}
                    {/* <input
                        type="date"
                        className={`form-control py-2 ${errors.date_entretien && "is-invalid"}`}
                        id="name"
                        {...register("date_entretien")}
                    /> */}
                    <InputForm
                        type="time"
                        label="Heure"
                        htmlFor="heure"
                        required
                        register={{ ...register("heure_entretien") }}
                        error={errors.heure_entretien?.message}
                    />
                    {/* <input
                        type="time"
                        className={`form-control py-2 ${errors.heure_entretien && "is-invalid"}`}
                        id="name"
                        {...register("heure_entretien")}
                    /> */}
                    <InputForm
                        type="text"
                        placeholder="Lieu"
                        htmlFor="lieu"
                        required
                        register={{ ...register("lieu_entretien") }}
                        error={errors.lieu_entretien?.message}
                    />
                    {/* <input
                        type="text"
                        className={`form-control py-2 ${errors.lieu_entretien && "is-invalid"}`}
                        id="name"
                        {...register("lieu_entretien")}
                    /> */}
                    <div>
                        <div className='d-flex align-items-center mb-2'>
                            <input
                                type="checkbox"
                                defaultChecked
                                className={`input-form py-2 ${errors.is_presentiel && "is-invalid"}`}
                                id="name"
                                {...register("is_presentiel")}
                            />
                            <span style={{ fontSize: 12, marginLeft: 5 }}>En presentiel?</span>
                        </div>

                        {/* <InputForm
                            type="checkbox"
                            placeholder="En presentiel?"
                            htmlFor="lieu"
                            required
                            register={{ ...register("lieu_entretien") }}
                            error={errors.lieu_entretien?.message}
                        /> */}
                    </div>
                    {/* <input
                        type="text"
                        className={`form-control py-2 ${errors.is_presentiel && "is-invalid"}`}
                        id="name"
                        {...register("is_presentiel")}
                    /> */}
                    <Select
                        label="Type d'entretien"
                        htmlFor="type_entretien"
                        register={{ ...register('type_entretien') }}
                        selectOptions={typeFormations}
                    />
                    <div style={{ width: '150px' }} className='mt-2'>
                        <ButtonLoading
                            type="submit"
                            text="Convoquez"
                            loading={isConvocation}
                            className='bg-gradient-success text-light py-2 fw-bold'
                        />
                    </div>
                </form>
            </CustomModal>
        <div style={{
            backgroundColor: 'white',
            padding: 15,
            borderRadius: 15
        }}>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <TitleH3>Détails étudiant</TitleH3>
                    {JSON.stringify}
                {
                        data.data?.status_candidature === 'rejetter' ?
                        <Badge bg='danger'>Candidature rejetée</Badge> :
                            data.data?.status_candidature === 'convoque' ?
                                <Badge bg="success">Convoquée</Badge> : data.data?.status_candidature === 'valider' ?
                                <Badge bg="warning">Candidature validée</Badge> :
                                    data.data?.status_candidature === 'en_cours' ? <Badge>Nouvelle candidature</Badge> : null
                }
            </div>
            <div
                style={{
                    backgroundColor: '#f8f9fa',
                    padding: 20,
                    borderRadius: 15
                }}
                className='d-flex align-items-start justify-content-between'
            >
                <div className='d-flex gap-3'>
                    <div
                        style={{
                            width: '70px',
                            height: '70px',
                            backgroundColor: 'gray',
                            borderRadius: 10
                        }}>

                    </div>
                    <div>           
                        {/* <p style={{ fontWeight: 600 }}>{selectedCandidature.user.name}</p> */}
                            <TitleProfil>{data.data?.user?.name}</TitleProfil>
                        <TextProfil>
                                <MdEmail /> Email: <span className='fw-bold fs-6'>{data.data?.user?.email}</span>
                        </TextProfil>
                        <TextProfil>
                                <BsPhoneFill /> Tel: <span className='fw-bold fs-6'>{data.data?.user?.phone}</span>
                        </TextProfil>
                        <TextProfil>
                                <HiLocationMarker /> Adresse: <span className='fw-bold fs-6'>{data.data?.user?.location}</span>
                        </TextProfil>
                    </div>
                </div>
                <Button
                    className={'bg-gradient-dark text-light py-2'}
                    tooltipContent={''}
                    onClick={() => navigate(ENTREPRISE_HOME_PAGE_PATH + '/' + ENTREPRISE_CANDIDATURES_PAGE_PATH + `/${selectedCandidature.user.id}/` + ENTREPRISE_CANDIDAT_DETAILS_PAGE_PATH)}
                >
                    <AiFillEye /> Voir détails
                </Button>
            </div>

            <div className='mt-3'>
                <TitleH3>Détails offre</TitleH3>
                <div
                    style={{
                        backgroundColor: '#f8f9fa',
                        padding: 20,
                        borderRadius: 15
                    }}
                // className='d-flex align-items-start justify-content-between'
                >
                    {/* <h6 style={{ fontSize: 14, fontWeight: 700 }}>{selectedCandidature.offre.titre}</h6> */}
                        <TitleProfil>{data.data?.offre?.titre}</TitleProfil>
                    <TextProfil>
                            Description: <span className='fw-bold fs-6'>{data.data?.offre?.description}</span>
                    </TextProfil>
                    <TextProfil>
                        Type d'offre: <span className='fw-bold fs-6'>
                                <Badge bg='success'>{data.data?.offre?.offre_type}</Badge>
                        </span>
                    </TextProfil>
                    <TextProfil>
                            Postulé il y a <span className='fw-bold fs-6'>{dayjs(data.data?.created_at).fromNow(true)}</span>
                    </TextProfil>
                    <TextProfil>
                            Publiée il y a <span className='fw-bold fs-6'>{dayjs(data.data?.offre?.created_at).fromNow(true)}</span>
                    </TextProfil>
                </div>
            </div>
            <div className='d-flex gap-2 justify-content-between mt-2'>
                    {
                        data.data?.status_candidature === 'rejetter' ? null : data.data?.status_candidature === 'valider' ? null : data.data?.status_candidature === 'convoque' ? <>
                            <div style={{ width: '110px' }}>
                                <ButtonLoading
                                    onClick={() => rejectCandidature(selectedCandidatureId)}
                                    loading={isRejecting}
                                    text="Rejetez"
                                    className='bg-gradient-danger text-light py-2 fw-bold'
                                />
                            </div>
                            <div style={{ width: '110px' }}>
                                <ButtonLoading
                                    onClick={() => validateCandidature(selectedCandidatureId)}
                                    loading={isValidating}
                                    text="Validez"
                                    className='bg-gradient-success text-light py-2 fw-bold'
                                />
                            </div>
                        </> : selectedCandidature?.status_candidature === 'en_cours' ? <>
                                <div style={{ width: '110px' }}>
                                    <ButtonLoading
                                        onClick={() => rejectCandidature(selectedCandidatureId)}
                                        loading={isRejecting}
                                        text="Rejetez"
                                        className='bg-gradient-danger text-light py-2 fw-bold'
                                    />
                                </div>
                                <div style={{ width: '110px' }}>
                                    <ButtonLoading
                                        onClick={() => confirmCandidatureToOpenModal(selectedCandidatureId)}
                                        text="Convoquez"
                                        className='bg-gradient-success text-light py-2 fw-bold'
                                    />
                                </div>
                        </> : null
                    }

            </div>
        </div>
        </>
    )
}

export default DetailsCandidature