import React from 'react'
import Section from '../components/Section'
import LandingPageContainer from '../components/LandingPageContainer'
import SectionH2 from '../components/SectionH2'
import SectionParagrapheTitle from '../components/SectionParagrapheTitle'
import { TabContextProvider, TabsNavbar, TabsPanel } from '../components/tabs'
import TabItemImgRight from '../components/TabItemImgRight'
import marieme from '../assets/img/marieme.png'
import postuler from '../assets/img/postuler.png'
import etablissement from '../assets/img/etablissement.png'
import entreprise from '../assets/img/entreprise.png'
import TabItemImgLeft from '../components/TabItemImgLeft'

const TabsList = ["Bakeli Carrière", "Space étudiant", "Espace établissement", "Espace entreprise"];

const LesDifferentsActeurs = () => {
    return (
        <Section className='differents_acteurs_container'>
            <LandingPageContainer>
                <SectionH2>Bakeli Carrière: Les differentes Interfaces</SectionH2>
                <SectionParagrapheTitle>
                    La plateforme ultime pour la gestion de stages :
                    connectant les étudiants, les établissements et les entreprises pour une collaboration fluide et des opportunités de stage inégalées
                </SectionParagrapheTitle>

                <div className='mt-4'>
                    <TabContextProvider
                        defaultActivePanel={TabsList[0]}
                        isUseLocalStorage={false}
                    >
                        <TabsNavbar items={TabsList} />
                        <div className='container-lg p-0' style={{ position: "relative", minHeight: '400px' }}>
                            <TabsPanel id={TabsList[0]}>
                                <TabItemImgRight
                                    title="Bakeli Carriere est une plateforme"
                                    paragrapheText="Notre plateforme se positionne comme un soutien essentiel
pour accompagner les étudiants dans leur parcours
académique et professionnel, les entreprises dans
la recherche de talent et les établissements dans le suivi
des stages de leurs apprenants."
                                    btnText="Contactez-nous"
                                    imgSrc={marieme}
                                    alt="Bakeli carriere"
                                />
                            </TabsPanel>
                            <TabsPanel id={TabsList[1]}>
                                <TabItemImgLeft
                                    title="Avoir une plateforme de gestion des stages"
                                    paragrapheText="Vous êtes à la recherche d'un stage?
Vous êtes au bon endroit. Découvrez l'espace étudiant de notre plateforme  :
votre portail personnalisé pour explorer, postuler et réussir
vos stages en un seul endroit.
N'attendez plus,
postuler et inviter vos amis à faire pareil."
                                    btnText="Postuler sur une offre"
                                    imgSrc={postuler}
                                    alt="Espace etidiant"
                                />
                            </TabsPanel>
                            <TabsPanel id={TabsList[2]}>
                                <TabItemImgRight
                                    title="Avoir une plateforme de suivi des stages
de vos apprenants"
                                    paragrapheText="Soyez à la pointe de la gestion des stages avec
notre espace établissement, conçu pour vous offrir une
vision globale, simplifier les processus administratifs et
garantir une expérience de stage harmonieuse pour
vos étudiants."
                                    btnText="Contactez-nous"
                                    imgSrc={etablissement}
                                    alt="Bakeli carriere"
                                />
                            </TabsPanel>
                            <TabsPanel id={TabsList[3]}>
                                <TabItemImgLeft
                                title="Trouver les meilleurs profils du marché"
                                paragrapheText="Trouvez les talents les plus prometteurs grâce à notre espace
entreprise, où vous pouvez publier des offres de stage ,
gérer les candidatures , les conventions et promouvoir
votre entreprise  comme un acteur clé du développement
professionnel des étudiants."
                                    btnText="Contactez-Nous"
                                imgSrc={entreprise}
                                alt="Espace etidiant"
                            />
                            </TabsPanel>
                        </div>
                    </TabContextProvider>
                </div>
            </LandingPageContainer>
        </Section>
    )
}

export default LesDifferentsActeurs