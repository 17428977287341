import React, { useState } from 'react'
import { useEntreprise } from '../../../hooks/useEntreprise'
import { MdOutlineLocalOffer, MdOutlinePeopleOutline } from 'react-icons/md';
import ButtonLoading from '../../../components/ButtonLoading';
import AddEntrepriseDomaineModal from '../components/AddEntrepriseDomaineModal';
import ListeEntrepriseDomaines from './ListeEntrepriseDomaines';
import DetailsDomaine from './DetailsDomaine';

const MonCompteHomePage = () => {
    const { entreprise } = useEntreprise()
    // console.log({ entreprise });
    const [openAddDomaine, setOpenAddDomaine] = useState(false)

    const banniere = `${process.env.REACT_APP_BASE_URL}entreprisesBannieres/${entreprise.banniere}`
    const logo = `${process.env.REACT_APP_BASE_URL}entreprisesLogos/${entreprise.logo}`
    return (
        <>
            <AddEntrepriseDomaineModal show={openAddDomaine} close={() => setOpenAddDomaine(false)} />

            <div
                style={{
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgb(255, 255, 255)), url(${banniere})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: '50vh'
                }}
                className="shadow-sm"
            >
                <div
                    style={{ color: '#00172d' }}
                    className=' p-3 d-flex flex-column justify-content-end h-100'
                >
                    <div className='d-flex align-items-center gap-4'>
                        <div style={{
                            width: '100px',
                            height: '100px',
                            backgroundColor: 'white',
                            padding: 10,
                            boxShadow: '0px 0px 0px 10px rgba(10, 20, 30, .5)'
                        }}>
                            <img src={logo} alt='Volkeno' className='img-fluid shadow-sm' />
                        </div>
                        <div>
                            <h1 className='fs-2'>{entreprise.name}</h1>
                            {
                                entreprise.effectif ? <p
                                    className='d-flex align-items-center gap-1'
                                >
                                    <MdOutlinePeopleOutline size={25} />
                                    <span className='fw-bold'>{entreprise.effectif} Employés</span>
                                </p> : null
                            }

                            {
                                entreprise.secteur ? <p>
                                    <MdOutlineLocalOffer size={20} />
                                    <span className='fw-bold'>{entreprise.secteur}</span>
                                </p> : null
                            }
                        </div>
                    </div>

                </div>
            </div>
            <div className=''>
                <div style={{ width: '200px' }}>
                    <ButtonLoading
                        onClick={() => setOpenAddDomaine(true)}
                        text="Nouveau domaine"
                        className="btn-apply mt-3"
                    />
                </div>
                <div style={{ minHeight: '300px' }} className='mt-3 row mb-5' >
                    <div className='bg-white col-12 pt-2 pb-3 mb-5 rounded-2' >
                        <ListeEntrepriseDomaines />
                    </div>
                    {/* <div className='bg-white col-6 pt-2 pb-3 mb-5 rounded-2'>
                        <DetailsDomaine />
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default MonCompteHomePage