/* ===================== AUTH PATHS =================== */
export const LOGIN_API_ROUTE = "login";
export const REGISTER_ETABLISSEMENT_API_ROUTE = "register_etablissement";
export const REGISTER_ETUDIANT_API_ROUTE = "register_etudiant";
export const REFRESH_API_TOKEN = "/refresh";
export const LOGOUT_API_ROUTE = "logout";

/* =================== ENTREPRISES PATHS =================== */
export const GET_ALL_ENTREPRISES_API_ROUTE = "entreprises";
export const GET_ENTREPRISE_DETAILS_API_ROUTE = "detail_entreprise/";
export const GET_OFFRES_BY_ENTREPRISES_API_ROUTE = "offres_by_entreprises/";
export const GET_ENTREPRISE_KPI_API_ROUTE = "kpi_entreprise/"; // {idEntreprise}
export const GET_ALL_ENTREPRISES_DOMAINES_API_ROUTE = "domaines/entreprise/"; // {idEntreprise}
export const GET_ALL_AVAILABLE_DOMAINES_API_ROUTE = "domaines/available/"; // {idEntreprise}
export const GET_DETAILS_DOMAINE_BY_ID_API_ROUTE = "details_domaine/"; // {domaine_id}
export const ADD_ENTREPRISE_DOMAINES_API_ROUTE = "domaines/entreprise/";//{entreprise_id}
export const EDIT_ENTREPRISE_DOMAINE_API_ROUTE = "edit_domaine/"; // {domaine_id}
export const DELETE_ENTREPRISE_DOMAINE_API_ROUTE = "domaines/entreprise/"; // {idEntreprise/domaine_id}

/* ===================== ETUDIANTS PATHS =================== */
export const GET_ALL_ETABLISSEMENTS = 'etablissements'
export const GET_ETUDIANTS_BY_ETABLISSEMENT_API_ROUTE =
  "etudiants_by_etablissement";
export const ADD_CV_API_ROUTE = "resume";
export const GET_ETUDIANT_CV_API_ROUTE = "get_resume/";
export const DELETE_CV_API_ROUTE = "cv_delete/";
export const ADD_EXPERIENCE_API_ROUTE = "add_experience";
export const EDIT_EXPERIENCE_API_ROUTE = "experience_edit/";
export const DELETE_EXPERIENCE_API_ROUTE = "experience_delete/";
export const GET_ETUDIANT_EXPERIENCES_API_ROUTE = "experiences/";
export const ADD_FROMATION_API_ROUTE = "add_formation";
export const GET_ETUDIANT_FORMATIONS_API_ROUTE = "formations/";
export const EDIT_FORMATION_API_ROUTE = "formation_edit/";
export const DELETE_FORMATION_API_ROUTE = "formation_delete/";

/* ===================== MOTIVATIONS PATHS =================== */
export const ADD_MOTIVATION_API_ROUTE = 'add_motivation/'
export const GET_ETUDIANT_MOTIVATION_API_ROUTE = 'motivation/' // + {user_id}/
export const EDIT_ETUDIANT_MOTIVATION_API_ROUTE = 'motivation_edit/' // + {motivation_id}/
export const DELETE_ETUDIANT_MOTIVATION_API_ROUTE = 'motivation_delete/' // + {motivation_id}/

/* ===================== PROJETS PATHS =================== */
export const ADD_PROJET_API_ROUTE = 'ajout-project'
export const GET_ETUDIANT_PROJET_API_ROUTE = 'get-project-by-user/' //+ {user_id}
export const EDIT_ETUDIANT_PROJET_API_ROUTE = 'edit-project/'
export const DELETE_ETUDIANT_PROJET_API_ROUTE = 'delete-project/' // {project_id}

/* ===================== CERTIFICATIONS PATHS =================== */
export const ADD_CERTIFICATION_API_ROUTE = 'add_certification/'
export const GET_CERTIFICATIONS_BY_USER_API_ROUTE = 'get-certifications-by-user/' //+{user_id}
export const DELETE_CERTIFICATION_API_ROUTE = 'delete-certification/' // +{certification_id}
export const EDIT_CERTIFICATION_API_ROUTE = 'edit-certificat/' // +{certification_id}

/* ===================== OFFRES PATHS =================== */
export const GET_ALL_OFFRES_API_ROUTE = "offres";
export const GET_LATEST_OFFRES_API_ROUTE = "latest_offres";
export const GET_OFFRE_BY_ID_API_ROUTE = "offres";
export const ADD_OFFRE_BY_ENTREPRISE_API_ROUTE = "add_offre_entrprise/";
export const EDIT_OFFRE_BY_ENTREPRISE_API_ROUTE = "edit_offre_entrprise/";
export const DELETE_OFFRE_BY_ENTREPRISE_API_ROUTE = "delete_offre_entrprise/";
export const APPLY_TO_OFFRE_API_ROUTE = "apply_job";
export const GET_APPLIED_OFFRES_API_ROUTE = "applied_jobs_by_user/";
export const KPI_ETUDIANT_API_ROUTE = 'kpi_etudiant/'
// export const DETAILS_OFFRE_API_ROUTE = `${BASE_URL}offres/{id}/details'`;

/* ===================== ETABLISSEMENTS PATHS =================== */
export const ADD_ETUDIANTS_API_ROUTE = "add_etudiants";
export const KPI_ETABLISSEMENT_API_ROUTE = "kpi_etablissement/"
export const ETUDIANT_DETAILS_API_ROUTE = 'details_etudiant/' // + {iduser}

/* ===================== CANDIDATURES PATHS =================== */
export const VALIDATE_CANDIDATURE_ROUTE = 'valider-candidature/' // + {id}
export const REJECT_CANDIDATURE_ROUTE = 'rejeter-candidature/' // + {id}
export const CONVOQUER_CANDIDAT_ROUTE = 'convoquer-candidat/' // + {id}, payload: {date_entretien}
export const CANDIDATURE_SPONTANEE_ROUTE = 'candidature_spontane/' // + {user_id}/{domaine_id}
export const CANDIDATURES_ROUTE = 'candidatures-by-entreprise/' // + id_entrprise
export const CANDIDATURE_BY_ID_ROUTE = 'candidatures-by-id/' // + idCandidature