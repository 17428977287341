import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import NavigateBack from '../../../components/shared/NavigateBack'
import { MdOutlineLocalOffer, MdOutlinePeopleOutline } from 'react-icons/md'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { dayJsFormat } from '../../../utils/dayJsFormat'
import { useApplyToOfferMutation, useGetAppliedOffresQuery } from '../../../redux/slices/offres/offresSlice'
import ButtonLoading from '../../../components/ButtonLoading'
import { useUserId } from '../../../hooks/useUserId'
import { toastAlertError, toastAlertSuccess } from '../../../utils/toastAlerts'
import { CheckCircle, CheckSquare } from 'feather-icons-react/build/IconComponents'
import { useUser } from '../../../hooks/useUser'
const dayjs = require('dayjs')

dayJsFormat()


const DetailsOffreEtudiantPage = () => {
    const { selectedOffreCandidature } = useSelector(state => state.offresCandidatures)
    const [applyToOffer, { isLoading: isApplying }] = useApplyToOfferMutation();
    const isAuthenticated = localStorage.getItem("access_token");
    const { userId } = useUserId()
    const { data: appliedJobs, refetch: refetchOffres } = useGetAppliedOffresQuery(userId, {
        skip: !isAuthenticated,
    });
    const user = useUser()
    // const [isPostuling, setIsPostuling] = useState(false)

    const applyToJob = async (e, user_id, offre_id) => {
        e.preventDefault();

        await applyToOffer({
            user_id: userId,
            offre_id: selectedOffreCandidature.payload.id,
        })
            .unwrap()
            .then((res) => {
                res?.success === true && toastAlertSuccess(res?.message);
                refetchOffres()
            })
            .catch((err) => {
                // console.log(err);
                toastAlertError(err.message);
            });
    };


    const statusCandidature = appliedJobs?.candidatures?.filter(
        (item) =>
            item?.offre_id === selectedOffreCandidature.payload.id && item.user_id === userId
    ).length
    // console.log({ selectedOffreCandidature, appliedJobs, statusCandidature });

    return (
        <div>
            {/* <button
                onClick={() => navigate(-1)}
                type="button"
                className='navigate--back'
            >
                <TiArrowBackOutline />
            </button> */}
            <NavigateBack />

            <h3 className='mt-2 fs-4'>Details Offre</h3>

            <div className='bg-white vh-100 p-2 p-sm-3 pt-4 shadow-sm'>
                <div className='row'>
                    <div className='col-12'>
                        <h4 className='fs-5 fw-bold'>{selectedOffreCandidature?.payload?.titre}</h4>
                        <h6 className='fs-5 text-decoration-underline mt-2'>Description de l'offre</h6>
                        <p className='fs-6'>{selectedOffreCandidature?.payload?.description}</p>
                        <div className='d-flex gap-3 mt-3'>
                            <p>
                                <MdOutlineLocalOffer /> <span className='fs-6'>{selectedOffreCandidature?.payload?.offre_type}</span>
                            </p>
                            <p>
                                <AiOutlineClockCircle /> <span className='fs-6'>il y a {dayjs(selectedOffreCandidature?.payload?.created_at).fromNow(true)}</span>
                            </p>
                            <p>
                                <MdOutlinePeopleOutline />
                                <span className='fs-6'>
                                    {selectedOffreCandidature?.payload?.candidats.length} candidats
                                </span>
                            </p>
                        </div>
                        {
                            user.type !== 'etablissement' && <div style={{ width: statusCandidature ? '250px' : '100px' }}>
                            {
                                statusCandidature ? <p
                                    className='text-success d-flex align-items-center gap-1'
                                >
                                    <CheckCircle size={18} color="green" />Déja postulé
                                </p> : <ButtonLoading
                                    onClick={applyToJob}
                                    loading={isApplying}
                                    text="Postuler"
                                    className="btn-apply"
                                />
                            }
                        </div>
                        }

                    </div>
                </div>
            </div>

        </div>
    )
}

export default DetailsOffreEtudiantPage