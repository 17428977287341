import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../components/shared/Button";
import { LOGIN_PATH } from "../../constants/routes_path";
import logo from "../../image/logo.png";
import { useRegisterEtudiantMutation } from "../../redux/slices/authSlice/registerApiSlice";
import { toastAlertError, toastAlertSuccess } from "../../utils/toastAlerts";
import InputForm from '../../components/shared/InputForm';
import TitleH2 from '../../components/shared/TitleH2';
import { FormValidate, emailPattern, phoneNumberPattern } from "../../utils/validateForm";

const RegisterEtudiant = () => {
  // Form validation schema
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Veuillez remplir ce champ"),
    email: Yup.string().email("Cet email n'est pas valide"),
    password: Yup.string().min(8).max(32).required("Au moins 8 caractères"),
    location: Yup.string().required("Veuillez remplir ce champ"),
    phone: Yup.string().required("Veuillez fournir un numéro de téléphone"),
  });

  // functions to build form returned by useForm() hook
  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState: { errors }, setError } = useForm(formOptions);

  function verifyPhoneNumber(number) {
    if (number.match(phoneNumberPattern) !== null) {
      console.log("Phone number is valid.");
    } else {
      console.log("Phone number is invalid.");
    }
  }


  // React router navigate s
  const navigate = useNavigate();

  // Use register etudiant mutation
  const [registerEtudiant, { isLoading }] = useRegisterEtudiantMutation();

  const inscriptionEtudiant = (data) => {
    console.log({ data });
    if (data.phone.match(phoneNumberPattern) !== null) {
      if (data.email.match(emailPattern) !== null) {
        registerEtudiant(data)
          .unwrap()
          .then((res) => {
            console.log({ res });
            navigate(LOGIN_PATH);
            toastAlertSuccess(res.message);
          })
          .catch((err) => {
            console.log({ err });
            toastAlertError(err.data.message);
          });
      }
      else {
        setError('email', { type: 'custom', message: 'Email invalide. (Ex. email correcte xyz@gmail.com)' });
      }
    } else {
      setError('phone', { type: 'custom', message: 'Numéro de téléphone invalide.)' });
    }
  };

  return (
    <div className="auth-form p-2">
      {/* <div> */}
      <form onSubmit={handleSubmit(inscriptionEtudiant)}>
        <div className="text-center mt-5">
          <img src={logo} alt="" className="taille-img mb-4" />
          <TitleH2>Inscription Etudiant</TitleH2>
        </div>
        {/* <div className="col-md-6"> */}
        <InputForm
          placeholder="Nom Complet"
          type="text"
          register={{ ...register('name') }}
          htmlFor="name"
          // required
          error={errors.name?.message}
        />
        <InputForm
          placeholder="Adresse"
          type="text"
          register={{ ...register('location') }}
          htmlFor="location"
          // required
          error={errors.location?.message}
        />
        <InputForm
          placeholder="Mot de passe"
          type="password"
          register={{ ...register('password') }}
          htmlFor="password"
          // required
          error={errors.password?.message}
        />
        <InputForm
          placeholder="Téléphone"
          type="phone"
          register={{ ...register('phone') }}
          htmlFor="phone"
          // required
          error={errors.phone?.message}
        />
        <InputForm
          placeholder="Email"
          type="email"
          register={{ ...register('email') }}
          htmlFor="email"
          // required
          error={errors.email?.message}
        />
        {/* <label>Nom Complet</label>
              <input type="text" id="name" {...register("name")} /> */}

        {/* <label>Adresse</label>
              <input
                type="text"
                required
                id="location"
                {...register("location")}
              /> */}
              {/* <p className="errorMsg">{adresseError}</p>/ */}
        {/* <label>Mot de passe</label>
              <input
                type="password"
                autoFocus
                id="password"
                {...register("password")}
              /> */}
              {/* <div className="niveauPassword">
                <PasswordStrengthBar
                  password={password}
                  scoreWords={[
                    "Faible",
                    "Faible",
                    "Moyen",
                    "Moyen",
                    "excellent",
                  ]}
                  shortScoreWord={"tres court"}
                />
              </div> */}
        {/* </div> */}
        {/* <div className="col-md-6"> */}
        {/* <label>Téléphone</label>
              <input type="phone" autoFocus id="phone" {...register("phone")} /> */}
              {/* <p className="errorMsg">{phoneError}</p> */}
        {/* <label>Email</label> */}
        {/* <input type="email" autoFocus id="email" {...register("email")} /> */}
              {/* <p className="errorMsg">{emailError}</p> */}
        {/* </div> */}

        <div className=" mt-3">
            <Button
              type="submit"
            className="btn-add w-100 py-2"
            // disabled={email?.length === 0 || password?.length === 0}
            >
              {isLoading ? "Inscription en cours..." : "Valider"}
            </Button>

        </div>
        <p className="mt-3 text-center">
              Vous avez deja un compte{" "}
              <span onClick={() => navigate(LOGIN_PATH)} className="link">
                connectez-vous ici
              </span>{" "}
        </p>
        </form>
      {/* </div> */}
    </div>
  );
};

export default RegisterEtudiant;
