import { AiFillHome } from "react-icons/ai";
import { ENTREPRISE_CANDIDATURES_PAGE_PATH, ENTREPRISE_MES_OFFRES_PATH, MON_COMPTE } from "../constants/routes_path";
import { IoMdSchool } from "react-icons/io";
import { MdAccountBalanceWallet } from 'react-icons/md'

export const entrepriseLinks = [
    {
        icon: AiFillHome,
        to: '',
        label: 'Accueil'
    },
    {
        icon: IoMdSchool,
        to: ENTREPRISE_CANDIDATURES_PAGE_PATH,
        label: "Candidatures"
    },
    {
        icon: AiFillHome,
        to: ENTREPRISE_MES_OFFRES_PATH,
        label: "Offres"
    },
    {
        icon: MdAccountBalanceWallet,
        to: MON_COMPTE,
        label: "Mon compte"
    },
    // {
    //     icon: IoMdSchool,
    //     to: ENTREPRISE_ENTITIES_PAGE_PATH,
    //     label: "Etablissement"
    // },


    // {
    //     to: ENTREPRISES_PATH,
    //     label: "Entreprises"
    // },
    // {
    //     to: ETABLISSEMENT_HOME_PAGE_PATH,
    //     label: "Etablissements"
    // },
    // {
    //     to: "",
    //     label: "Mes Offres"
    // },
].reverse()