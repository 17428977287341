import React from 'react'

const InputForm = ({ label, register, htmlFor, required, type, error, placeholder, ...props }) => {
    return (
        <div className="mb-3">
            <label htmlFor={htmlFor} className="form-label">
                {label}
            </label>
            <input
                placeholder={placeholder}
                type={type}
                className={`input-form ${error && " form-is-invalid"}`}
                id={htmlFor}
                {...register}
                required={required}
                {...props}
            />
            {error && <span className='fs-6 text-danger'>{error}</span>}
        </div>
    )
}

export default InputForm