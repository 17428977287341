import React from 'react'
import EntityTab from '../components/EntityTab';
import { Outlet } from 'react-router-dom';

const EntitiesHomePage = () => {
    return (
        <>
            {/* <EntityTab /> */}
            <Outlet />
        </>
    )
}

export default EntitiesHomePage