import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Navbar from '../../layouts/Navbar/Navbar'
import EtablissementDash from './EtablissementDash'
import { DETAILS_OFFRES_CANDIDATURE_FROM_ENTREPRISE_DETAILS_PATH, ETABLISSEMENT_PROFIL_PATH, STUDENT_DETAILS_PATH } from '../../constants/routes_path'
import DetailsEtudiantPage from '../ListeEtudiants/DetailsEtudiantPage'
import EtablissementProfilPage from './EtablissementProfilPage'
import DetailsEntrepriseEtudiantPage from '../Etudiant/entreprise/DetailsEntrepriseEtudiantPage'
import DetailsOffreEtudiantPage from '../Etudiant/offres/DetailsOffreEtudiantPage'

const EtablisssementHomePage = () => {
    return (
        <div>
            <Navbar />
            <div className='container-lg mt-5 px-4'>
                <Routes>
                    <Route path='/*' element={<EtablissementDash />} />
                    <Route path={STUDENT_DETAILS_PATH} element={<DetailsEtudiantPage />} />
                    <Route path={ETABLISSEMENT_PROFIL_PATH} element={<EtablissementProfilPage />} />
                    <Route path={DETAILS_OFFRES_CANDIDATURE_FROM_ENTREPRISE_DETAILS_PATH} element={<DetailsOffreEtudiantPage />} />
                </Routes>
            </div>
        </div>
    )
}

export default EtablisssementHomePage