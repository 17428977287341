import React, { cloneElement, useState } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
import { HiOutlineMail, HiPlus } from "react-icons/hi";
import { IoMdSchool } from "react-icons/io";
import { useParams } from "react-router-dom";
import Button from "../../components/shared/Button";
import CardOnEmptyProfilData from "../../components/CardOnEmptyProfilData";
import FormationListItem from "../../components/FormationListItem";
import ResourceDataMap from "../../components/shared/ResourceDataMap";
import ResourceDataProfil from "../../components/ResourceDataProfil";
import { useGetEtudiantInfoQuery } from "../../redux/slices/etablissements/etablissementsSlice";
import { SpinnerLoaderSearch } from "../../utils/spinnersLoader";
import { monthYearFormatter } from "../../utils/timeFormatters";
import CvInfoItem from '../Profile/CvInfoItem';
import ProjetListItem from "../Profile/projet/components/ProjetListItem";
import CertificationGallery from "../Profile/certification/CertificationGallery";
import CertificationListItem from "../Profile/certification/components/CertificationListItem";
import { setSelectedCertification } from "../../redux/reducers/certificationReducer";
import { useDispatch } from "react-redux";
import ExperienceCardItem from "../Profile/experience/ExperienceCardItem";
import ProjectImagesDetails from "../Profile/projet/ProjectImagesDetails";
import { setSelectedProject } from "../../redux/reducers/projetReducer";
import NavigateBack from "../../components/shared/NavigateBack";
// import { setSelectedCertification } from "../../redux/reducers/certificationReducer";

// User infos section
const UserInfo = ({ user = { user: {} } }) => {
    // TODO: fetcher le user ici
    const dataEtablissement = JSON.parse(localStorage.getItem("user"));

    // console.log( dataEtablissement );
    return (
        <div className="white-bg px-2 py-3 mx-auto">
            <div className="d-flex align-items-center justify-content-center">
                <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmYiIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBmaWxsPSIjRjZGNkY5IiBkPSJNMCAwaDEyOHYxMjhIMHoiLz48cGF0aCBmaWxsPSIjQkRCREMwIiBkPSJNMTYgMTE2YzAtMTYuNTY5IDEzLjQzMS0zMCAzMC0zMGgzNmMxNi41NjkgMCAzMCAxMy40MzEgMzAgMzB2MjQuODM3YzAgMTYuNTY5LTEzLjQzMSAzMC0zMCAzMEg0NmMtMTYuNTY5IDAtMzAtMTMuNDMxLTMwLTMwVjExNlptNzYtNjRjMCAxNS40NjQtMTIuNTM2IDI4LTI4IDI4UzM2IDY3LjQ2NCAzNiA1MnMxMi41MzYtMjggMjgtMjggMjggMTIuNTM2IDI4IDI4WiIvPjwvc3ZnPgo="
                    alt="Candidat"
                    // onError={"https://img.myloview.com/stickers/default-avatar-profile-icon-vector-social-media-user-image-700-205124837.jpg"}
                    className=" w-25 rounded-circle"
                />
            </div>
            <h2 className="text-center mt-2 profil-name">{user.user.name ?? '...........'}</h2>
            <div className="px-2 profil-body">
                <p className="mx-1 mb-1">
                    <HiOutlineMail /> {user.user.email ?? '...........'}
                </p>

                <p className="mx-1 mb-1">
                    <BsFillTelephoneFill /> {user.user.phone ?? '...........'}
                </p>

                <p className="mx-1 mb-1">
                    <FiMapPin /> {user.user.location ?? '...........'}
                </p>
                <p>
                    <IoMdSchool size={20} /> {dataEtablissement.name ?? "Pas ecore d'école"}
                </p>
            </div>
        </div>
    );
};


// Complementary infos
const ComplementaryInfos = ({ loadingCv, userCv, loadingFormations, userFormations, userExperiences, loadingExperiences, isLoading, userProjets, userCertifications }) => {
    const [showCertificationProject, setShowCertificationProject] = useState(false)
    const [showImageProject, setShowImageProject] = useState(false)
    const dispatch = useDispatch()
    // !open project images
    const openImages = (selectedProject) => {
        // console.log({ selectedProject });
        dispatch(setSelectedProject(selectedProject))
        setShowImageProject(true)
    }
    // !open project images
    const openCertificationImages = (selectedCertification) => {
        // console.log({ selectedCertification });
        dispatch(setSelectedCertification(selectedCertification))
        setShowCertificationProject(true)
    }
    const ResourceItemFormation = ({ formation }) => cloneElement(<FormationListItem formation={formation} />)
    const ResourceItemCv = ({ userCv }) => cloneElement(<CvInfoItem userCv={userCv} />)
    const ClonedProjetListItem = ({ projet }) => cloneElement(<ProjetListItem projet={projet} />, { openImages })
    const ClonedCertificationListItem = ({ certification }) => cloneElement(<CertificationListItem certification={certification} />, { openCertificationImages })
    // const ClonedExperienceCardItem = ({ experience }) => cloneElement(<ExperienceCardItem experience={experience} />)


    // console.log({ userCv: userCv, userExpe: userExperiences, userFor: userFormations, userId });

    return (
        <>

            {/* *********************************** cv **************************************** */}
            {
                isLoading ? <div className="d-flex justify-content-center">
                    <SpinnerLoaderSearch />
                </div> : <>
                    <ResourceDataProfil
                        title="CV"
                        isLoading={loadingCv}
                        // resourceData={Object.entries(userCv)}
                        resourceData={userCv}
                    >
                        <ResourceDataMap
                            // resourceData={Object.entries(userCv)}
                            resourceData={(userCv)}
                            resourceName="userCv"
                            resourceItem={ResourceItemCv}
                        />
                        <CardOnEmptyProfilData
                            titleOnEmpty=""
                            textOnEmpty="Cv non disponible"
                            addBtnText=""
                            onClick={null}
                        />
                    </ResourceDataProfil>


                    <ResourceDataProfil
                            accordion
                        onAdd={null}
                        title="Formations"
                        isLoading={loadingFormations}
                        resourceData={userFormations}
                    >
                        <ResourceDataMap
                            resourceData={userFormations}
                            resourceName="formation"
                            resourceItem={ResourceItemFormation}
                        // resourceItem={FormationListItem}
                        />
                        <CardOnEmptyProfilData
                            titleOnEmpty=""
                            textOnEmpty="Formations non disponible"
                            addBtnText=""
                            onClick={null}
                        />
                    </ResourceDataProfil>

                        {/* +++++++++++++++++++++++++++++++++++++++++ PROJETS ++++++++++++++++++++++++++++++++++++++ */}
                        <ProjectImagesDetails
                            show={showImageProject}
                            close={() => setShowImageProject(false)}
                        />
                        <ResourceDataProfil
                            accordion
                            onAdd={null}
                            title="Projets"
                            isLoading={false}
                            resourceData={userProjets}
                        >
                            <ResourceDataMap
                                resourceData={userProjets}
                                resourceName="projet"
                                resourceItem={ClonedProjetListItem}
                            // resourceItem={FormationListItem}
                            />
                            <CardOnEmptyProfilData
                                titleOnEmpty=""
                                textOnEmpty="Projets non disponible"
                                addBtnText=""
                                onClick={null}
                            />
                        </ResourceDataProfil>

                        {/* +++++++++++++++++++++++++++++++++++++++++ CERTIFICATIONS ++++++++++++++++++++++++++++++++++++++ */}
                        <CertificationGallery
                            show={showCertificationProject}
                            close={() => setShowCertificationProject(false)}
                        />
                        <ResourceDataProfil
                            accordion
                            onAdd={null}
                            title="Certifications"
                            isLoading={false}
                            resourceData={userCertifications}
                        >
                            <ResourceDataMap
                                resourceData={userCertifications}
                                resourceName="certification"
                                resourceItem={ClonedCertificationListItem}
                            // resourceItem={FormationListItem}
                            />
                            <CardOnEmptyProfilData
                                titleOnEmpty=""
                                textOnEmpty="Certifications non disponibles"
                                addBtnText=""
                                onClick={null}
                            />
                        </ResourceDataProfil>

                        {/* +++++++++++++++++++++++++++++++++++++++++ EXPERIENCES ++++++++++++++++++++++++++++++++++++++ */}
                        <ResourceDataProfil
                            accordion
                            onAdd={null}
                            title="Expériences"
                            isLoading={loadingExperiences}
                            resourceData={userExperiences}
                        >
                            <ResourceDataMap
                                resourceData={userExperiences}
                                resourceName="experience"
                                resourceItem={ExperienceCardItem}
                            />
                            <CardOnEmptyProfilData
                                titleOnEmpty=""
                                textOnEmpty="Expériences non disponibles"
                                addBtnText=""
                                onClick={null}
                            />
                        </ResourceDataProfil>

                        {/* <div className="d-flex align-items-center justify-content-between m-2">
                        <h4 className="fw-bold">Experiences</h4>
                        {userExperiences?.count >= 1 && (
                            <Button className="btn-add" onClick={null}>
                                <HiPlus /> Ajouter une experience
                            </Button>
                        )}
                    </div>

                    {loadingExperiences ? (
                        <div className="d-flex align-items-center justify-content-center white-bg loader_heigth">
                            <SpinnerLoaderSearch />
                        </div>
                    ) : userExperiences?.length >= 1 ? (
                        userExperiences?.map((experience, index) => (
                            <div className="row mb-3 white-bg px-2 py-3 mx-auto" key={index}>
                                <div className="col-xl-9 col-lg-8 col-md-8 col-sm-7">
                                    <span
                                        className={`badge rounded-pill text-center ${experience?.poste_occupe?.toLowerCase()}`}
                                    >
                                        {experience?.poste_occupe}
                                    </span>
                                    <h6 className="m-0">{experience?.name}</h6>
                                    <p>{experience?.nom_entreprise}</p>
                                    <p>{`${monthYearFormatter(experience?.date_debut)} - ${experience?.date_fin !== null
                                        ? monthYearFormatter(experience?.date_fin)
                                        : "aujourd'hui"
                                        }`}</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <CardOnEmptyProfilData
                            titleOnEmpty=""
                            textOnEmpty="Expériences non disponible"
                            addBtnText=""
                            onClick={null}
                        />
                    )} */}
                </>
            }
        </>
    );
};

const DetailsEtudiantPage = () => {
    const { userId } = useParams()

    const { data = { cv: [], etudiant: [], experience: [], formation: [], candidature: [] }, isLoading } = useGetEtudiantInfoQuery(userId)

    const { etudiant } = data

    return (
        <>
            <div className="mb-3">
                <NavigateBack />
            </div>
        <div className="row">
            <div className="col-xl-4 col-lg-4 col-12 mb-md-3 mb-3 mx-auto px-0">
                <UserInfo user={etudiant[0]} userId={Number(userId)} />
            </div>
            <div className="col-xl-8 col-lg-8 col-12 mb-3 px-sm-0 ps-xl-4 ps-lg-4">
                <ComplementaryInfos
                    isLoading={isLoading}
                    loadingCv={false}
                    userCv={data?.cv}
                    loadingFormations={false}
                    userFormations={data?.formation}
                    loadingExperiences={false}
                    userExperiences={data?.experience}
                        userProjets={data?.projets}
                        userCertifications={data?.certifications}
                />
            </div>
        </div>
        </>
    );
};

export default DetailsEtudiantPage;
