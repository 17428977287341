import React from 'react'
import { BiLinkAlt } from 'react-icons/bi'
// import { FaGithubAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import Gallery from '../../../components/shared/Gallery'
import LinkBadge from '../projet/components/LinkBadge'

const CertificationGallery = ({ show, close }) => {
    const { selectedCertification } = useSelector(state => state.certifications)
    const certificationName = selectedCertification?.name
    const certificationDescription = selectedCertification?.description
    const lien_certification = selectedCertification.lien_certification

    const images = []
    selectedCertification?.images?.forEach(element => {
        images.push({ original: process.env.REACT_APP_BASE + element.url, thumbnail: process.env.REACT_APP_BASE + element.url })
    });
    // console.log({ selectedCertification });

    return (
        <>
            <Gallery
                show={show}
                close={close}
                name={certificationName}
                description={certificationDescription}
                images={images}
                title="certification"
            >
                <>
                    {
                        lien_certification ? <LinkBadge
                            icon={<BiLinkAlt size={13.5} />}
                            link={lien_certification}
                            text="Lien certification"
                            bgColor="#4b4453"
                        /> : null
                    }
                </>
            </Gallery>
        </>
    )
}

export default CertificationGallery