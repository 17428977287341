import React, { cloneElement, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetEntrepriseDetailsQuery } from '../../../redux/slices/entreprises/entreprisesSlice'
import { useDispatch } from 'react-redux'
import { setSelectedOffreCandidature } from '../../../redux/reducers/offresCandidature'
import { DETAILS_OFFRES_CANDIDATURE_FROM_ENTREPRISE_DETAILS_PATH, ETABLISSEMENT_HOME_PAGE_PATH, ETUDIANT_HOME_PAGE_PATH } from '../../../constants/routes_path'
import EntrepriseOffreCard from '../../Entreprises/components/EntrepriseOffreCard'
import NavigateBack from '../../../components/shared/NavigateBack'
import LoaderSpinner from '../../../components/LoaderSpinner'
import { MdOutlineLocalOffer, MdOutlinePeopleOutline } from 'react-icons/md'
import ResourceDataMapperWithEmptyMessage from '../../../components/shared/ResourceDataMapperWithEmptyMessage'
import { useUser } from '../../../hooks/useUser'
import ButtonLoading from '../../../components/ButtonLoading'
import CandidatureSpontane from '../../Entreprises/components/CandidatureSpontane'

const DetailsEntrepriseEtudiantPage = () => {
    const [openCandidatureSpontaneeModal, setopenCandidatureSpontaneeModal] = useState(false)
    const { id } = useParams()
    const { data: entreprise, isLoading } = useGetEntrepriseDetailsQuery({ id_entreprise: id })
    const user = useUser()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const navigateToCandidatureDetails = (offre) => {
        dispatch(setSelectedOffreCandidature(offre))
        if (user.type === "etablissement") {
            return navigate(ETABLISSEMENT_HOME_PAGE_PATH + DETAILS_OFFRES_CANDIDATURE_FROM_ENTREPRISE_DETAILS_PATH)
        }
        // !redirection
        navigate(ETUDIANT_HOME_PAGE_PATH + DETAILS_OFFRES_CANDIDATURE_FROM_ENTREPRISE_DETAILS_PATH)
    }

    const banniere = `${process.env.REACT_APP_BASE_URL}entreprisesBannieres/${entreprise?.data[0]?.banniere}`
    const name = entreprise?.data[0].user.name
    const secteur = entreprise?.data[0].secteur
    const effectif = entreprise?.data[0].effectif
    const offres = entreprise?.data[0].offres

    const ClonedEntrepriseOffreCard = ({ offre }) => cloneElement(<EntrepriseOffreCard offre={offre} className="col-sm-6 col-12 p-1" />, { navigateToCandidatureDetails })

    const openCandidatureSpontaneModal = (entreprise_id) => {
        console.log({ entreprise_id });
        // setSelectedEntrepriseId(entreprise_id)
        setopenCandidatureSpontaneeModal(true)
    }

    return (
        <div className='bg-white p-2 p-sm-3 mb-5'>
            <CandidatureSpontane
                show={openCandidatureSpontaneeModal}
                close={() => setopenCandidatureSpontaneeModal(false)}
                entreprise_id={id}
            />
            <div className='mb-2'>
                <NavigateBack />
            </div>
            <div
                style={{
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8), rgb(255, 255, 255)), url(${banniere})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: '50vh'
                }}
                className="shadow-sm"
            >
                <div
                    style={{ color: '#00172d' }}
                    className=' p-3 d-flex flex-column justify-content-end h-100'
                >
                    <h1 className='fs-2'>{!isLoading ? name ?? '' : <LoaderSpinner />}</h1>
                    {
                        effectif ? <p
                            className='d-flex align-items-center gap-1'
                        >
                            <MdOutlinePeopleOutline size={25} />
                            <span className='fw-bold'>{effectif} Employés</span>
                        </p> : null
                    }

                    {
                        secteur ? <p>
                            <MdOutlineLocalOffer size={20} />
                            <span className='fw-bold'>{secteur}</span>
                        </p> : null
                    }

                </div>
            </div>

            {
                user.type === "etudiant" && <div style={{ width: '200px' }} className='mt-2'>
                    <ButtonLoading
                        onClick={(e) => {
                            e.stopPropagation()
                            setopenCandidatureSpontaneeModal(true)
                            // openCandidatureSpontaneModal(id)
                        }}
                        text="Candidature spontanée"
                        type="button"
                        className="btn-apply mt-3"
                    />
                </div>
            }

            <div className='row mt-3'>
                <div
                    className='col-12 pt-3'
                >
                    <h3 className='fs-5 fw-bold'>Liste offres</h3>
                    <div
                        style={
                            {
                                // height: '70vh',
                                overflow: 'auto'
                            }}
                        className="p-2 py-4 row"
                    >
                        <ResourceDataMapperWithEmptyMessage
                            isLoading={isLoading}
                            resourceItem={ClonedEntrepriseOffreCard}
                            resourceName="offre"
                            resourceData={offres}
                            emptyMessage="Pas encore d'offres"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailsEntrepriseEtudiantPage