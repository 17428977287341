import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./slices/apiSlice/apiSlice";
import authReducer from "./slices/authSlice/authSlice";
import projects from './reducers/projetReducer'
import certifications from './reducers/certificationReducer'
import offresCandidatures from './reducers/offresCandidature'
import offres from './reducers/offreReducer'
import candidatures from "./reducers/candidatureReducer";
import etudiants from './reducers/etudiantReducer'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
// import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  projects,
  certifications,
  offresCandidatures,
  offres,
  etudiants,
  candidatures
})

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== "production",
});


export const persistor = persistStore(store)