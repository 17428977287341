import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import Button from "../../components/shared/Button";
import { LOGIN_PATH } from "../../constants/routes_path";
import logo from "../../image/logo.png";
import { useRegisterEtablissementMutation } from "../../redux/slices/authSlice/registerApiSlice";
import { toastAlertError, toastAlertSuccess } from "../../utils/toastAlerts";
import "./register.css";

const RegisterEtablissement = () => {
  // Form validation schema
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Obligatoire"),
    email: Yup.string().required("Obligatoire"),
    phone: Yup.string().required("Obligatoire"),
    password: Yup.string().required("Obligatoire"),
    location: Yup.string().required("Obligatoire"),
    raison_sociale: Yup.string().required("Obligatoire"),
    description: Yup.string().required("Obligatoire"),
  });

  // functions to build form returned by useForm() hook
  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit } = useForm(formOptions);

  // Use register etudiant mutation
  const [registerEtablissement, { isLoading }] =
    useRegisterEtablissementMutation();

  const navigate = useNavigate();

  // Register function
  const handleRegister = (data) => {
    registerEtablissement(data)
      .unwrap()
      .then((res) => {
        navigate(LOGIN_PATH);
        toastAlertSuccess(res.message);
      })
      .catch((err) => {
        toastAlertError(err?.message);
      });
  };

  return (
    <section className="register">
      <div className="registerContainer">
        <div className="title">
          <img src={logo} alt="" className="taille-img" />
          <h4 className="text-center mb-3 mt-3">Inscription établissement</h4>
        </div>
        <form className="mb-3" onSubmit={handleSubmit(handleRegister)}>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nom de l'établissement
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  {...register("name")}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  {...register("email")}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Téléphone
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  {...register("phone")}
                />
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <textarea
                    className="form-control"
                    placeholder="Leave a comment here"
                    id="description"
                    {...register("description")}
                    style={{ height: "100px" }}
                  ></textarea>
                  <label htmlFor="description">Description</label>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="location" className="form-label">
                  Adresse
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="location"
                  {...register("location")}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="raison_sociale" className="form-label">
                  Raison sociale
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="raison_sociale"
                  {...register("raison_sociale")}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Mot de passe
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  {...register("password")}
                />
                {/* <div className="niveauPassword">
                  <PasswordStrengthBar
                    password={password}
                    scoreWords={[
                      "Faible",
                      "Faible",
                      "Moyen",
                      "Moyen",
                      "excellent",
                    ]}
                    shortScoreWord={"tres court"}
                  />
                </div> */}
              </div>
            </div>
          </div>

          <div className="btnContainer btnInsc">
            <Button
              type="submit"
              hasCTA
              className="btn-add col-md-4"
              isLoading={isLoading}
            >
              M'inscrire
            </Button>
          </div>

          <p className="mt-5">
            Vous avez deja un compte{" "}
            <span onClick={() => navigate(LOGIN_PATH)} className="link">
              connectez-vous ici
            </span>{" "}
          </p>
        </form>
      </div>
    </section>
  );
};

export default RegisterEtablissement;
