import React from 'react'
import { MdEdit } from 'react-icons/md'

const MotivationItem = ({ motivation, editMotivation }) => {
    // console.log({ motivation: motivation.data[0].motivations });
    return (
        <div className='row mb-4 white-bg px-2 py-3 mx-auto formation-card'>
            <div className='d-flex justify-content-end'>
                <button
                    className="btn btn-edit align-self-start"
                    onClick={() => editMotivation(motivation)}
                >
                    <MdEdit size={18} />
                </button>
            </div>
            <div className='d-flex justify-content-between py-2'>
                <p className='fs-5'>
                    {
                        motivation?.motivations
                    }
                </p>
            </div>
        </div>
    )
}

export default MotivationItem