import React from 'react'
import { BiLocationPlus, BiMailSend, BiPhone } from 'react-icons/bi';
import TypeBadge from './TypeBadge';
import ThreeDots from '../../../components/shared/ThreeDots';

const CandidatListItem = ({
    candidat,
    navigateToCandidatDetails,
    validateCandidature,
    confirmCandidatureToOpenModal
}) => {
    // console.log({ candidat });
    return (
        <div
            style={{ cursor: 'pointer' }}
            onClick={() => navigateToCandidatDetails(candidat)}
            className='mb-3 shadow p-3 w-100 position-relative'
        >
            {/* <div className='text-end'>
                <ThreeDots>
                    <div className='d-flex flex-column' >
                        <button
                            className='w-100 py-1 my-1 dropdown-btn'
                            onClick={() => validateCandidature(candidat.id)}
                        >
                            valider
                        </button>
                        <button
                            className='w-100 py-1 my-1 dropdown-btn dropdown-btn-red'
                        >
                            rejeter
                        </button>
                        <button
                            className='w-100 py-1 my-1 dropdown-btn dropdown-btn-yellow'
                            onClick={() => {
                                console.log({ candidat });
                                confirmCandidatureToOpenModal(candidat)
                            }}
                        >
                            convoquer
                        </button>
                    </div>
                </ThreeDots>
            </div> */}
            <h6 className='m-0 fs-6'>{candidat.name} <TypeBadge text={candidat.type} bgCOlor="#6CD7A5" /> </h6>
            <div className='d-flex flex-wrap gap-2 mt-3'>
                <p className='m-0 fs-6'> <BiPhone /> {candidat.phone}</p>
                <p className='m-0 fs-6'> <BiMailSend /> {candidat.email}</p>
                <p className='m-0 fs-6'> <BiLocationPlus /> {candidat.location}</p>
            </div>
        </div>
    )
}

export default CandidatListItem