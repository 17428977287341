import {
  LOGIN_API_ROUTE,
  LOGOUT_API_ROUTE,
} from "../../../constants/api_endpoints";
import { apiSlice } from "../apiSlice/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: LOGIN_API_ROUTE,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: LOGOUT_API_ROUTE,
        method: "POST",
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApiSlice;
