import React, { useEffect } from 'react'
import TitleH2 from '../../components/shared/TitleH2'
import { useGetByEntrepriseQuery } from '../../redux/slices/candidature/candidatureSlce'
import { useEntreprise } from '../../hooks/useEntreprise'
import { Badge } from 'react-bootstrap'
import paginationFactory from 'react-bootstrap-table2-paginator';
import { AiFillEye } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedCandidature, setSelectedCandidatureId } from '../../redux/reducers/candidatureReducer'
import { useNavigate } from 'react-router-dom'
import { ENTREPRISE_CANDIDATURE_DETAILS_PAGE_PATH } from '../../constants/routes_path'
import BootstrapTable from 'react-bootstrap-table-next'
// import overlayFactory from 'react-bootstrap-table2-overlay';
import { TbFileUpload } from 'react-icons/tb'
// import InputChange from '../../components/shared/InputChange'
import { SpinnerLoaderSearch } from '../../utils/spinnersLoader'

import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
// import { textFilter } from 'react-bootstrap-table2-filter'

const EntrepriseCandidaturePage = () => {
    const { entreprise } = useEntreprise()
    const { data = { data: [], count: 0 }, isLoading } = useGetByEntrepriseQuery({ id_entreprise: entreprise?.id })
    const { candidaturesList = [] } = useSelector(state => state.candidatures)
    // console.log({ entreprise });
    const { data: candidatures = [], count } = data
    const dispatch = useDispatch()
    const navigate = useNavigate()

    console.log({ candidatures });

    // useEffect(() => {
    //     dispatch(setCandidatureList(candidatures))
    // }, [isLoading, isFetching])
    // console.log({ candidaturesList });
    // console.log({ candidatures });
    const goToDetails = (row) => {
        navigate(ENTREPRISE_CANDIDATURE_DETAILS_PAGE_PATH)
        dispatch(setSelectedCandidatureId(row.id))
        dispatch(setSelectedCandidature(row))
    }
    const columns = [
        {
            dataField: 'user',
            text: 'Prénom & Nom',
        formatter: (cell, row) => <span
            onClick={() => goToDetails(row)}
            style={{ cursor: 'pointer' }}
        >{row?.user?.name}</span>
    },
    {
            dataField: 'cv',
        text: 'Cv',
            formatter: (cell, row) => <a
                href={process.env.REACT_APP_BASE_URL + 'resume/' + row?.user?.cv?.fichier}
                target='_blank'
                rel='noreferrer'
                style={{ cursor: 'pointer' }}
            >{row?.user?.cv?.fichier ? <TbFileUpload size={25} /> : 'vide'}</a>
        },
        {
            dataField: 'projets',
            text: 'Projets',
            formatter: (cell, row) => <span
            // style={{ cursor: 'pointer' }}
            >{row?.user?.projets?.length}</span>
        },
        {
            dataField: 'certifications',
            text: 'Certifications',
            formatter: (cell, row) => <span
            // style={{ cursor: 'pointer' }}
            >{row?.user?.certifications?.length}</span>
        },
        {
            dataField: 'experiences',
            text: 'Expériences',
            formatter: (cell, row) => <span
            // style={{ cursor: 'pointer' }}
            >{row?.user?.experiences?.length}</span>
        },
        // {
        // dataField: 'offre',
        //     text: 'Offres',
        //     formatter: (cell, row) => {
        //         return <span
        //             onClick={() => goToDetails(row)}
        //             style={{ color: '#7b809a', cursor: 'pointer' }}
        //         >{row?.offre?.titre}</span>
        //     }
        // },
        candidatures.some(item => item.offre && item.offre.offre_type !== undefined) ?
            {
                dataField: 'offre.offre_type',
                text: 'Offre Type',
                // filter: textFilter({
                //     placeholder: 'Recherche'
                // }),
                formatter: (cell, row) => {
                    if (cell === null || cell === undefined) {
                        return <Badge bg='danger'>spontané</Badge>;
                    }
                    return <Badge bg='success'>{cell}</Badge>;
                }
            } :
            null,
        {
            dataField: 'domaine',
            text: 'Domaine',
            formatter: (cell, row) => {
                return <span
                    onClick={() => goToDetails(row)}
                    style={{ color: '#7b809a', cursor: 'pointer' }}
                >{row?.domaine?.name}</span>
            }
        },
        {
        dataField: 'name',
        text: 'Statut',
            formatter: (cell, row) => row?.status_candidature === 'rejetter' ? <Badge bg='danger'>rejetée</Badge> : row?.status_candidature === 'convoque' ? <Badge bg="success">convoquée</Badge> : row?.status_candidature === 'valider' ? <Badge bg="warning">validée</Badge> : row?.status_candidature === 'en_cours' ? <Badge>Nouvelle</Badge> : null
    },
    {
        dataField: 'actions',
        text: 'Actions',
        formatter: (cell, row) => <button
            className='btn'
            onClick={() => goToDetails(row)}
        >
            <AiFillEye />
        </button>
    },
    ];
    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>
                <div style={{ flex: 2 }}>
            <TitleH2>Liste des candidats <Badge>{count}</Badge> </TitleH2>
                </div>
                {/* <div style={{ flex: 1 }}>
                    <form>
                        <InputChange
                            type='text'
                            value={currentCandidatureOffre}
                            onChange={(value) => dispatch(filterCandidatureByOffre(value))}
                            placeholder="Recherchez par offre ou par types d'offres"
                        />
                    </form>
                </div> */}
            </div>
            {
                isLoading ? <SpinnerLoaderSearch /> : <BootstrapTable
                keyField='id'
                    columns={columns.filter(Boolean)}
                    data={candidatures}
                pagination={paginationFactory()}
                // loading={true}
            // overlay={overlayFactory()}
            />
            }
        </div>
    )
}

export default EntrepriseCandidaturePage