import React from 'react'
import "react-image-gallery/styles/css/image-gallery.css";
import { useSelector } from 'react-redux';
import LinkBadge from './components/LinkBadge';
import { FaGithubAlt } from 'react-icons/fa';
import Gallery from '../../../components/shared/Gallery';

const ProjectImagesDetails = ({ show, close }) => {
    const { selectedProject } = useSelector(state => state.projects)
    const projectName = selectedProject?.name
    const projectDescription = selectedProject?.description
    const lien_depot = selectedProject?.lien_depot
    const link_project = selectedProject?.link_project
    const images = []
    selectedProject?.images?.forEach(element => {
        images.push({ original: process.env.REACT_APP_BASE + element.url, thumbnail: process.env.REACT_APP_BASE + element.url })
    });
    // console.log({ selectedProject });
    // console.log({ selectedProject: selectedProject.payload?.images });
    // const images = [
    //     {
    //         original: 'https://picsum.photos/id/1018/1000/600/',
    //         thumbnail: 'https://picsum.photos/id/1018/250/150/',
    //     },
    //     {
    //         original: 'https://picsum.photos/id/1015/1000/600/',
    //         thumbnail: 'https://picsum.photos/id/1015/250/150/',
    //     },
    //     {
    //         original: 'https://picsum.photos/id/1019/1000/600/',
    //         thumbnail: 'https://picsum.photos/id/1019/250/150/',
    //     },
    // ];
    return (
        <>
            <Gallery
                show={show}
                close={close}
                name={projectName}
                description={projectDescription}
                images={images}
                title="projet"
            >
                <>
                    {
                        lien_depot ? <LinkBadge
                            icon={<FaGithubAlt size={13.5} />}
                            link={lien_depot}
                            text="Lien dépôt"
                            bgColor="#4b4453"
                        /> : null
                    }
                    {
                        link_project ? <LinkBadge
                            link={link_project}
                            text="Lien projet"
                            bgColor="#c34a36"
                        /> : null
                    }</>
            </Gallery>
        </>
    )
}

export default ProjectImagesDetails