import React from 'react'
import Section from '../components/Section'
import LandingPageContainer from '../components/LandingPageContainer'
import SectionH2 from '../components/SectionH2'
import FlexBetween from '../components/FlexBetween'
import ObjectifsCardItem from '../components/ObjectifsCardItem'

const ObjectifsSection = () => {
    return (
        <Section className='bg-white'>
            <LandingPageContainer>
                <SectionH2>Objectif 2023: 10 000 jeunes inséres en stage</SectionH2>
                <FlexBetween className='mt-5'>
                    <ObjectifsCardItem className='bg-color-red'>
                        Informer
                    </ObjectifsCardItem>
                    <ObjectifsCardItem className='bg-color-orange' >
                        Orienter
                    </ObjectifsCardItem>
                    <ObjectifsCardItem className='bg-color-green' >
                        Placer
                    </ObjectifsCardItem>
                </FlexBetween>
            </LandingPageContainer>
        </Section>
    )
}

export default ObjectifsSection