import React from 'react'
import { styled } from 'styled-components'
import { HiLocationMarker, HiOutlineClock, HiOutlinePaperClip } from 'react-icons/hi'
import { dayJsFormat } from '../../../utils/dayJsFormat'
import { useSelector } from 'react-redux'
import { onError } from '../../../utils/onError'

const dayjs = require('dayjs')

dayJsFormat()

const CardPane = styled.div`
    padding: 16px;
    gap: 17px;
    isolation: isolate;
    background: #fff;
    border: 1px solid #d6cee4;
    box-shadow: 0 5px 5px rgba(213,205,227,.5);
    border-radius: 10px;
    cursor: pointer;
    border: ${({ active }) => active ? '2px solid #0573e8' : null};
    display: flex;
    flex-direction:column;
    justify-content:space-between
`
const CompanyLogoPane = styled.img`
    width: 60px;
    height: 60px;
    object-fit: contain;
    background-position: 50%;
    background-size: 70%;
    background-color: #fff;
    background-repeat: no-repeat;
    border: 3px solid #d6cee4;
    border-radius: 50px;
`
const CardBody = styled.div`
    width: 100%;
`
const CardTitle = styled.h3`
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #0d0140;
`
const TruncateParagraphe = styled.p`
    text-overflow: ellipsis;
    overflow-hidden
`

const OffreCardItem = ({
    offre = { titre: '', offre_type: '', created_at: '', entreprise: { user: {} } },
    navigateToDetails
}
) => {
    const { offreId } = useSelector(state => state.offres)
    const { id, titre, offre_type, created_at, entreprise } = offre
    // console.log({ offre });
    return (
        <CardPane
            id={id + offre_type}
            active={offre.id === offreId ? true : false}
            onClick={() => navigateToDetails({ offre_id: offre.id, offre_name: offre.titre, refId: id + offre_type })}
        >
            <CompanyLogoPane
                src={`${process.env.REACT_APP_BASE_URL}entreprisesLogos/${entreprise?.logo}`}
                alt='company'
                onError={onError}
            />
            {/* <img src={`${process.env.REACT_APP_BASE_URL}entreprisesLogos/${entreprise?.logo}`} alt='company' /> */}
            {/* </CompanyLogoPane> */}
            <CardBody>
                <CardTitle>{titre}</CardTitle>
                <div className='d-flex align-items-center px-1 mt-3'>
                    <HiOutlinePaperClip /> {offre_type}
                </div>
                <div className='d-flex w-100 justify-content-between mt-3'>
                    <div className='d-flex align-items-center flex-grow px-1'>
                        <HiLocationMarker />
                        <TruncateParagraphe>
                            {entreprise.user.location}
                        </TruncateParagraphe>
                    </div>
                    <div className='d-flex align-items-center px-1'>
                        <HiOutlineClock />
                        <TruncateParagraphe>Il y à {dayjs(created_at).fromNow(true)}</TruncateParagraphe>
                    </div>
                </div>
            </CardBody>
            <div className='mt-2'>
                {
                    !offre?.date_expiration ? <span className='text-warning fs-6'> Pas de date d'expiration specifiée</span> : new Date(offre?.date_expiration) < new Date() ? <span className='fw-bold text-danger'>Offre expirée 🚫</span> : <p className='text-success'>
                        Disponible jusqu'au {new Date(offre?.date_expiration).toLocaleString('fr-Fr', {
                            dateStyle: 'short'
                        })}
                    </p>
                }
            </div>
        </CardPane>
    )
}

export default OffreCardItem