import React from "react";
// import { BsPatchCheckFill } from "react-icons/bs";
import { AiFillForward } from "react-icons/ai";
import { AiFillMail } from "react-icons/ai";
import { AiFillCloseSquare } from "react-icons/ai";
import { onError } from "../utils/onError";
import { Badge } from "react-bootstrap";
import { styled } from "styled-components";

// import Button from "./shared/Button";
// import "./Entreprises.css";

const CardTitlePane = styled.h5`
  color: #000;
  font-family: Montserrat;
  font-size: 20.777px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

const CandidaturesEtudiants = ({
  titre,
  type,
  description,
  datePublication,
  location,
  image,
  entreprise,
  applyFunction,
  isLoading,
  isAbleToApply,
  isNotEtudiant,
  statusCandidature,
}) => {
  return (
    (
      <div
        style={{
          // boxShadow: '0 1px -1px 1px rgba(20,30,40,.2), 0 2px 3px rgba(0,0,0,.2)',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
          borderRadius: 0
        }}
        className="row card flex-row border-0 m-0 mx-auto bg-white rounded offresCard bg-white py-0 px-2 mb-3">
        <div className="col-xl-1 col-lg-2 col-md-2 col-sm-12 mb-sm-3 mb-3 p-0">
          <img
            className="img-fluid"
            src={image} alt=""
            onError={onError}
          />
        </div>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 card-body py-0 m-0 pt-4">
          <CardTitlePane>{entreprise}</CardTitlePane>
          {/* <div className="d-flex align-center justify-content-between">
          <span className="badge text-bg-dark">Primary</span>
        </div> */}
          <p className="fw-bold">{titre ?? "Candidature spontanée"}</p>
          <div className="row">
            <div className="col-xl-10 col-lg-10 col-md-9 mb-2">
              <span>{`${type ?? ""} | ${location ?? ""} | Publiée le ${datePublication}`}</span>
            </div>
            { }
            {statusCandidature === "en_cours" ? (
              <div className="stepper-wrapper">
                <div className="stepper-item completed">
                  <div className="step-counter">
                    <AiFillForward size={15} />
                  </div>
                  <div className="step-name fs-6">Postulé</div>
                </div>
                <div className="stepper-item active">
                  <div className="step-counter">...</div>
                  <div className="step-name fs-6 text-center">En traitement</div>
                </div>
                <div className="stepper-item active">
                  <div className="step-counter"></div>
                  <div className="step-name"></div>
                </div>
              </div>
            ) : (
              <> </>
            )}

            {statusCandidature[0] === "c" ? (
              <div className="stepper-wrapper">
                <div className="stepper-item completed">
                  <div className="step-counter">
                    <AiFillForward size={15} />
                  </div>
                  <div className="step-name fs-6">Postulé</div>
                </div>
                <div className="stepper-item completed">
                  <div className="step-counter-orange">
                    <AiFillMail size={15} />
                  </div>
                  <div className="step-name fs-6 text-center">Convoqué en entretien</div>
                </div>
                {/* <div className="stepper-item active">
                  <div className="step-counter">...</div>
                  <div className="step-name">En traitement</div>
                </div> */}
              </div>
            ) : (
              <> </>
            )}
            {statusCandidature[0] === "r" ? (
              <div className="stepper-wrapper">
                <div className="stepper-item completed">
                  <div className="step-counter">
                    <AiFillForward size={15} />{" "}
                  </div>
                  <div className="step-name fs-6">Postulé</div>
                </div>
                {/* <div className="stepper-item completed">
                  <div className="step-counter-orange">
                    <AiFillMail />
                  </div>
                  <div className="step-name">Entretien effectué </div>
                </div> */}
                <div className="stepper-item completed">
                  <div className="step-counter-red">
                    <AiFillCloseSquare size={15} />
                  </div>
                  <div className="step-name fs-6">Candidature {statusCandidature}</div>
                </div>
              </div>
            ) : (
              <> </>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default CandidaturesEtudiants;
