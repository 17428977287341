import {
  GET_ALL_ENTREPRISES_API_ROUTE,
  GET_ENTREPRISE_DETAILS_API_ROUTE,
  GET_ENTREPRISE_KPI_API_ROUTE,
  GET_OFFRES_BY_ENTREPRISES_API_ROUTE,
} from "../../../constants/api_endpoints";
import { apiSlice } from "../apiSlice/apiSlice";

export const entreprisesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllEntreprises: builder.query({
      query: () => GET_ALL_ENTREPRISES_API_ROUTE,
    }),
    // detail_entreprise/id-entreprise
    getEntrepriseDetails: builder.query({
      query: ({ id_entreprise }) => `${GET_ENTREPRISE_DETAILS_API_ROUTE}${id_entreprise}`,
    }),
    getOffresByEntreprise: builder.query({
      query: ({ id_entreprise }) => {
        // console.log({ id_entreprise });
        return `${GET_OFFRES_BY_ENTREPRISES_API_ROUTE}${id_entreprise}`
      },
      providesTags: ["Offres"],
      transformResponse: (response) => response.data,
    }),
    getEntrepriseKpi: builder.query({
      query: ({ id_entreprise }) => {
        // console.log({ id_entreprise });
        return `${GET_ENTREPRISE_KPI_API_ROUTE}${id_entreprise}`
      },
      // transformResponse: (response) => {
      //   console.log({ response });
      // },
    }),
  })
});

export const {
  useGetAllEntreprisesQuery,
  useGetOffresByEntrepriseQuery,
  useGetEntrepriseDetailsQuery,
  useGetEntrepriseKpiQuery
} =
  entreprisesSlice;
