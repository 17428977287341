import React from 'react'
import LandingBtn from './LandingBtn'

const TabItemImgLeft = ({ title, paragrapheText, btnText, imgSrc, alt }) => {
    return (<div className='row'>
        <div className='col-md-6 col-12'>
            <img src={imgSrc} alt={alt} className='img-fluid rounded' />
        </div>
        <div className='col-md-6 col-12 d-flex flex-column justify-content-between mt-3 mt-sm-0'>
            <div>
                <h4 className='tab_item_title'>{title}</h4>
                <p className='tab_item_text'>{paragrapheText}</p>
            </div>
            <div>
                <LandingBtn className='bg-color-green rounded'>{btnText}</LandingBtn>
            </div>
        </div>
    </div>)
}

export default TabItemImgLeft