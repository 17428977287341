import React from "react";
import { Tooltip } from 'react-tooltip'
import { styled } from "styled-components";

const BtnPane = styled.button`
  height: 44.621px;
  flex-shrink: 0;
  border-radius: 2.606px;
  border:none;
  font-weight: 600
`

const Button = ({ type, className, onClick, isLoading, children, tooltipContent }) => {
  return (
    <>
      <BtnPane
        data-tooltip-id="tooltip"
        data-tooltip-content={tooltipContent}
        data-tooltip-place="top"
        type={type}
        className={`${className} px-3`}
        onClick={onClick}
        disabled={isLoading}
      >
        {children}
      </BtnPane>
      <Tooltip id="tooltip" place="top" style={{ fontSize: '12px', padding: '0.2rem' }} />
    </>
  );
};

export default Button;
