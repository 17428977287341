import React from 'react'

const ButtonLoading = ({ loading, text, onClick, className, type }) => {
    return (
        <button disabled={loading} onClick={onClick} type={type ?? "submit"} className={`btn ${className} w-100 d-flex align-items-center justify-content-center mt-2`}>
            {
                !loading ? <span>{text}</span> :
                    <div
                        style={{ width: '20px', height: '20px' }} className="spinner-border ms-3"
                        role="status"
                    >
                        <span className="visually-hidden">Loading...</span>
                    </div>
            }
        </button>
    )
}

export default ButtonLoading