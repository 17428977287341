import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CheckAuthStatus, CheckLandinPageAuthStatus, ProtectedRoutes } from "./routes/ProtectedRoutes";
import { landingPageRoutes, privateRoutes, publicRoutes } from "./routes/routes";
// import routes from "./routes/routes";
import 'animate.css';
import "./styles/globalStyles.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-tooltip/dist/react-tooltip.css'

const recursiveRoutes = (routes) => {
  return <>
    {
      routes.map((route, index) => {
        if (route.children) {
          return <Route path={route.path} element={route.element} key={index}>
            {recursiveRoutes(route.children)}
          </Route>
        }
        return <Route path={route.path} element={route.element} key={index} />
      })
    }
  </>
}


function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route element={<ProtectedRoutes />}>
          {privateRoutes.map((item, index) => (
            <Route key={index} path={item.path + '/*'} element={item.element} />
          ))}
        </Route>

        <Route element={<CheckAuthStatus />}>
          {/* {publicRoutes.map((item, index) => (
            <Route key={index} path={item.path} element={item.element} />
          ))} */}
          {
            recursiveRoutes(publicRoutes)
          }

        </Route>
        <Route element={<CheckLandinPageAuthStatus />}>
          {/* {publicRoutes.map((item, index) => (
            <Route key={index} path={item.path} element={item.element} />
          ))} */}
          {
            recursiveRoutes(landingPageRoutes)
          }

        </Route>
      </Routes>
    </div>
  );
}

export default App;
