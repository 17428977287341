import React from 'react'
import { MdEdit, MdOutlineLocalOffer } from 'react-icons/md'
import { truncateString } from '../../../utils/truncateString';
import { FaUserAlt } from 'react-icons/fa';
import TypeBadge from './TypeBadge';
import Wysiwyg from '../../../components/shared/Wysiwyg';

const EntrepriseOffreCard = ({ offre, navigateToCandidatureDetails, className, editOffre }) => {

    return (
        <div
            onClick={() => navigateToCandidatureDetails(offre)}
            className={`${className ?? 'col-sm-6 col-12 mt-2'}  align-items-center`}
            style={{ minHeight: '250px', cursor: 'pointer' }}
        >
            <div
                style={{
                    backgroundColor: 'white',
                    // boxShadow: '-2px 5px 5px rgba(118,118,120,0.2)',
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                    height: '100%'
                }}
                className='align-items-center gap-2 p-3 '
            >
                <div className='d-flex justify-content-between align-items-center'>
                    <div
                        style={{
                            // height: '100%'
                        }}
                        className='pe-sm-2'
                    >
                        <div
                            className='d-flex justify-content-center align-items-center'
                            style={{
                                width: '40px',
                                height: '40px',
                                backgroundColor: 'rgba(15,20,25,0.3)',
                                color: 'white',
                                borderRadius: '50px',
                                boxShadow: '0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2)'
                            }}
                        >
                            <MdOutlineLocalOffer size={20} />
                        </div>
                    </div>
                    {
                        editOffre ? <button
                            onClick={(e) => {
                                e.stopPropagation()
                                editOffre(offre)
                            }}
                            className='btn btn-edit p-0 px-2 border'
                            type="button"
                        >
                            <MdEdit size={15} />
                        </button> : null
                    }

                </div>
                <div className='col-sm-10 col-12 ps-sm-0 mt-2 h-100'>
                    <div>
                        <h2
                            style={{ lineHeight: '26px', fontSize: '16px' }}
                            className='mb-1'
                        >
                            {offre.titre}
                            <TypeBadge text={offre.offre_type} bgCOlor="#51C6B1" />
                        </h2>
                        <p className='fs-6 mt-2'>
                            <Wysiwyg contenu={truncateString(offre.description, 200)} />
                        </p>
                    </div>
                    <p
                        style={{ color: '#717173', fontSize: '13px', lineHeight: '24px' }}
                        className='fs-6 m-0 mt-3'
                    >
                        <FaUserAlt />
                        <span className='ms-1 fw-bold'>
                            Total candidats :
                            <span className='ms-1'>
                                {offre.candidats.length}
                            </span>
                        </span>
                        <div className='mt-2'>
                            {
                                !offre?.date_expiration ? <span className='text-warning'> Pas de date d'expiration specifiée</span> : new Date(offre?.date_expiration) < new Date() ? <span className='fw-bold text-danger'>Offre expirée 🚫</span> : <p className='text-success'>
                                    Disponible jusqu'au {new Date(offre?.date_expiration).toLocaleString('fr-Fr', {
                                        dateStyle: 'short'
                                    })}
                                </p>
                            }

                        </div>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default EntrepriseOffreCard