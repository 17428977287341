import React from 'react'
import Section from '../components/Section';
import LandingPageContainer from '../components/LandingPageContainer';
import SocialMediaIcon from '../components/SocialMediaIcon';
import { ArrowRight, Facebook, Linkedin, Twitter } from 'feather-icons-react/build/IconComponents';

const Footer = () => {
    return (
        <Section className='footer_section'>
            <LandingPageContainer>
                <div className='row'>
                    <div className='col-md-3 col-sm-6 col-12 p-0'>
                        <h4 className='footer_title'>Lorem ipsum</h4>
                        <p className='footer_text_top'>Lorem ipsum dolor</p>
                        <p className='footer_text_top'>Lorem ipsum dolor</p>
                        <p className='footer_text_top'>Lorem ipsum dolor</p>
                        <p className='footer_text_top'>Lorem ipsum dolor</p>
                        <p className='footer_text_top'>Lorem ipsum dolor</p>
                    </div>
                    <div className='col-md-3 col-sm-6 col-12 p-0 mt-3 mt-sm-3 mt-md-0'>
                        <h4 className='footer_title'>Informations</h4>
                        <p className='footer_text_top'>FAQ</p>
                        <p className='footer_text_top'>Blog</p><p className='footer_text_top'>Support</p>
                    </div>
                    <div className='col-md-3 col-sm-6 col-12 p-0 mt-3 mt-sm-3 mt-md-0'>
                        <h4 className='footer_title'>Entreprise</h4>
                        <p className='footer_text_top'>Accueil</p><p className='footer_text_top'>Contexte</p>
                        <p className='footer_text_top'>Bakeli Carrière </p><p className='footer_text_top'>Objectifs</p>
                        <p className='footer_text_top'>Avantages</p><p className='footer_text_top'>Contact</p>
                    </div>
                    <div className='col-md-3 col-sm-6 col-12 p-0 mt-3 mt-sm-3 mt-md-0 mb-3 mb-md-0'>
                        <form className='footer_form'>
                            <h4 className='footer_title'>S'abonner</h4>
                            <div className='position-relative'>
                                <input
                                    placeholder='Adresse e-mail'
                                    type='text'
                                    className='footer_input'
                                />
                                <button className='footer_form_btn'>
                                    <ArrowRight />
                                </button>
                            </div>
                            <p className='form_text'>
                                Lorem ipsum dolor sit amet consectetur. Mollis massa fames elit aliquet pretium enim felis libero sit. Vehicula nibh semper.
                            </p>
                        </form>
                    </div>
                </div>
                <hr />
                <div className='d-flex justify-content-between align-items-center flex-wrap gap-3'>
                    <h5>Bakeli carrière</h5>
                    <div className='d-flex flex-wrap gap-4'>
                        <p className='footer_text'>Conditions</p>
                        <p className='footer_text'>Confidentialité</p>
                        <p className='footer_text'>Cookies</p>
                    </div>
                    <div className='d-flex gap-2'>
                        <SocialMediaIcon>
                            <Linkedin />
                        </SocialMediaIcon>
                        <SocialMediaIcon>
                            <Facebook />
                        </SocialMediaIcon>
                        <SocialMediaIcon>
                            <Twitter />
                        </SocialMediaIcon>
                    </div>
                </div>
            </LandingPageContainer>
        </Section>
    )
}

export default Footer