export const DEFAULT_PATH = "*";

/* ===================== LANDINGPAGE PATHS =================== */
export const LANDINGPAGE_PATH = "/";
export const LANDINGPAGE_OFFRES_PATH = "/offres";
export const LANDINGPAGE_OFFRES_DETAILS_PATH = ":offre_name";

/* ===================== AUTH PATHS =================== */
export const LOGIN_PATH = "/login";
export const REGISTER_PATH = "/register";
export const REGISTER_ETABLISSEMENT_PATH = "/register/etablissement";
export const REGISTER_ETUDIANT_PATH = "/register/etudiant";

/* ===================== ETUDIANT PATHS =================== */
export const ETUDIANT_HOME_PAGE_PATH = '/etudiant/'
export const ETUDIANT_ENTREPRISES_PAGE_PATH = 'entreprises'
export const ETUDIANT_CANDIDATURES_PAGE_PATH = 'mes_candidatures'
export const DETAILS_OFFRES_CANDIDATURE_FROM_ENTREPRISE_DETAILS_PATH = "details-offres";
/* ===================== ETUDIANT PATHS =================== */
export const ETABLISSEMENT_HOME_PAGE_PATH = '/etablissement/'

/* ===================== DASHBOARD PATHS =================== */
export const DASHBOARD_PATH = "/dashboard";

/* ===================== OFFRES PATHS =================== */
export const OFFRES_PATH = "offres";
export const EDIT_OFFRE_PATH = "edit";
export const OFFRE_DETAILS_PATH = "/details_offres/:id";

/* ===================== ENTREPRISES PATHS =================== */
export const ENTREPRISE_HOME_PAGE_PATH = '/entreprise'
export const ENTREPRISE_CANDIDATURES_PAGE_PATH = 'candidatures'
export const ENTREPRISE_CANDIDATURE_DETAILS_PAGE_PATH = 'candidature-details'
export const ENTREPRISE_CANDIDAT_DETAILS_PAGE_PATH = 'candidat'
export const ENTREPRISE_ENTITIES_PAGE_PATH = 'entities'
export const ENTREPRISE_MES_OFFRES_PATH = 'offres'
export const ENTREPRISES_PATH = "/entreprises";
export const ETUDIANTS_PATH = "/etudiants";
export const AJOUT_ETUDIANTS_PATH = "ajout-etudiant";
export const MES_CANDIDATURES = "mes_candidatures";
export const OFFRES_BY_ENTREPRISES_PATH = "/entreprises/offres";
export const DETAILS_OFFRES_CANDIDATURE_PATH = "details";

/* ===================== ENTREPRISES PATHS =================== */
export const MY_PROFILE_PAGE = "profil";
export const MON_COMPTE = "compte";

/* ===================== ETABLISSEMENT PATHS =================== */
// export const STUDENT_DETAILS_PATH = "etudiant/details/:id/:nomEtudiant";
export const STUDENT_DETAILS_PATH = "etudiant/:userId/details";
export const ETABLISSEMENT_DETAILS_ENTREPRISE_PATH = "/etablissement/e/entreprises/:id/details";
export const ETABLISSEMENT_PROFIL_PATH = "mon-compte";
export const ETABLISSEMENT_ENTREPRISE_PATH = "entreprises";