import React, { useMemo } from 'react'
import ResourceDataMap from '../../components/shared/ResourceDataMap'
import CandidatureCardItem from '../../components/CandidatureCardItem'
import { Link, Route, Routes } from 'react-router-dom'
import { etudiantRoutes } from '../../routes/routes'
import { ETUDIANT_HOME_PAGE_PATH, MES_CANDIDATURES } from '../../constants/routes_path'
import { useUserId } from '../../hooks/useUserId'
import { useGetOffresKpisQuery } from '../../redux/slices/offres/offresSlice'

const EtudiantDash = () => {
    const { userId } = useUserId()
    const { data } = useGetOffresKpisQuery(userId)
    const {
        all_candidature,
        nbr_convocation,
        nbr_rejet,
        nbr_valide
    } = data || { all_candidature: '...', nbr_convocation: '...', nbr_rejet: '...', nbr_valide: '...' }

    const dashboardKpis = useMemo(() => {
        return [
            { text: "Offres Postulées", number: all_candidature, className: "mb-3", to: ETUDIANT_HOME_PAGE_PATH + MES_CANDIDATURES },
            { text: "Nombre de convocations", number: nbr_convocation, className: "mb-3", to: ETUDIANT_HOME_PAGE_PATH + MES_CANDIDATURES },
            { text: "Offres Validées", number: nbr_valide, className: "mb-3", to: ETUDIANT_HOME_PAGE_PATH + MES_CANDIDATURES },
            { text: "Offres Rejetées", number: nbr_rejet, className: "mb-3", to: ETUDIANT_HOME_PAGE_PATH + MES_CANDIDATURES },
        ]
    }, [all_candidature, nbr_convocation, nbr_valide, nbr_rejet])
    return (
        <div className='container-lg mt-3 p-2'>
            <div className=''>
                <div className='row p-0'>
                    {
                        <ResourceDataMap
                            resourceItem={({ kpi }) => {
                                return <div className='col-sm-4 col-md-3'>
                                    <Link to={kpi.to} className={`${kpi.className} d-block`}>
                                        <CandidatureCardItem text={kpi.text} number={kpi.number} />
                                    </Link>
                                </div>
                            }}
                            resourceName="kpi"
                            resourceData={dashboardKpis}
                        />
                    }
                </div>
                <div className='p-2'>
                    <Routes>
                        {
                            etudiantRoutes.map((route, index) => {
                                if (route.children) {
                                    return <Route key={index} path={route.path} element={route.element} >
                                        {
                                            route.children.map(chilRoute => (
                                                <Route key={chilRoute.path} path={chilRoute.path} element={chilRoute.element} />
                                            ))
                                        }
                                    </Route>
                                }
                                return <Route key={index} path={route.path} element={route.element} />
                            })
                        }
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default EtudiantDash