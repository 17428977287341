import React from "react";
import OffresResourceDataMap from "../../components/OffresResourceDataMap";
import PaginatedItems from "../../components/shared/PaginationItems";
import SearchFilter from "../../components/SearchFilter";
import {
  useGetAllOffresQuery,
} from "../../redux/slices/offres/offresSlice";
import { SpinnerLoaderSearch } from "../../utils/spinnersLoader";
import TitleH2 from "../../components/shared/TitleH2";

const Offres = () => {
  // Initial states
  // const [currentElement, setCurrentElement] = React.useState(null);
  const [searchItem, setSearchItem] = React.useState("");

  // Get Additional data
  // const user = JSON.parse(localStorage.getItem("user"));
  // const isAuthenticated = localStorage.getItem("access_token");

  //   Authenticated user applied offers query
  // const { data: appliedJobs } = useGetAppliedOffresQuery(user?.id, {
  //   skip: !isAuthenticated,
  // });

  // All offers query
  const {
    data: offres,
    isLoading,
    isSuccess,
    isFetching,
    currentData,
    // refetch,
  } = useGetAllOffresQuery(searchItem);

  console.log({ currentData });
  // Reset all states
  const resetStates = () => {
    setSearchItem("");
  };

  return (
    // <Main>
    <div className="pb-5 px-0">
      <SearchFilter
        inputState={searchItem}
        inputSetState={setSearchItem}
        resetState={resetStates}
      />
      <div className="mt-3">
        {searchItem && <TitleH2>{!isFetching ? offres?.count : '...'} Résultats pour <strong style={{ color: '#009688' }}>{searchItem}</strong></TitleH2>}
      </div>
      <div>
        {isLoading || (isFetching && !currentData) ? (
          <div className="d-flex align-items-center justify-content-center mt-4">
            <SpinnerLoaderSearch />
          </div>
        ) : isSuccess ? (
          offres?.count >= 1 ? (
            <PaginatedItems
                itemsPerPage={10}
              ressourceData={offres?.data}
            >
              <OffresResourceDataMap />
            </PaginatedItems>
          ) : (
            <p className="text-center mt-3">Aucune offres pour le moment.</p>
          )
        ) : (
          <p className="text-center mt-3">Une erreur s'est produite lors du chargement</p>
        )}
      </div>
    </div>
    // </Main >
  );
};

export default Offres;
