import React from 'react'

const ObjectifsCardItem = ({ children, className }) => {
    return (
        <div className={`d-flex justify-content-center align-items-center rounded-5 p-5 objectif_card_item ${className}`}>
            {children}
        </div>
    )
}

export default ObjectifsCardItem