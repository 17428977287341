import React from 'react'
import LandingBtn from './LandingBtn'
import { useNavigate } from 'react-router-dom'
import { LOGIN_PATH } from '../../../constants/routes_path'

const Banniere = () => {
    const navigate = useNavigate()
    return (
        <div
            className='banniere_container'
        >
            <div className='landing_page_container d-flex justify-content-start align-items-center h-100'>
                <div className=''>
                    <p className='banniere-bg-p animate__animated animate__backInLeft'>Un etudiant, un stage</p>
                    <h1 className='animate__animated animate__backInLeft'>Insérer les Jeunes Formés</h1>
                    <p className='animate__animated animate__backInLeft'>Révolutionnez la gestion des stages avec notre plateforme tout-en-un,
                        offrant une expérience optimale aux étudiants, aux établissements
                        et aux entreprises, du début à la fin</p>
                    <div className='d-flex flex-wrap gap-3 mt-2'>
                        <LandingBtn
                            onClick={() => navigate(LOGIN_PATH)}
                            className='bg-color-green animate__animated animate__backInLeft'
                        >
                            Postuler sur une offre
                        </LandingBtn>
                        <LandingBtn className='bg-color-red animate__animated animate__backInLeft'>Contactez nous</LandingBtn>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banniere