import React from 'react'
import { BsEye } from 'react-icons/bs';
import { FaGithubAlt } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import LinkBadge from './LinkBadge';

const ProjetListItem = ({ projet, editProjet, openImages }) => {
    // console.log({ projet });
    const { name, description, link_project, lien_depot, images } = projet

    // console.log({ images });
    return (
        <div className='card p-2 mb-2'>
            <div className='d-flex justify-content-between align-items-center mb-1'>
                <h6 className='fs-6'>{name}</h6>
                <button
                    className="btn btn-edit p-0 px-2 border"
                    onClick={() => editProjet(projet)}
                >
                    <MdEdit size={15} />
                </button>
            </div>
            <p className='fs-6'>
                {description}
            </p>
            <div className='d-flex flex-wrap gap-2'>
                {
                    lien_depot ? <LinkBadge
                        icon={<FaGithubAlt size={13.5} />}
                        link={lien_depot}
                        text="Lien dépôt"
                        bgColor="#4b4453"
                    /> : null
                }
                {
                    link_project ? <LinkBadge
                        link={link_project}
                        text="Lien projet"
                        bgColor="#c34a36"
                    /> : null
                }

                {
                    images.length ? <span
                        onClick={() => openImages(projet)}
                        style={{ backgroundColor: '#845ec2', color: 'white', fontWeight: 500, cursor: 'pointer' }}
                        className='rounded px-2 d-flex align-items-center'
                    >
                        <BsEye size={13.5} /> <span style={{ fontSize: '11px' }} className='ms-1'>Voir projet</span>
                    </span> : null
                }
            </div>
        </div>
    )
}

export default ProjetListItem