import React from 'react'

const LandingInput = ({ label, type, htmlFor }) => {
    return (
        <div className='w-100'>
            <label
                htmlFor={htmlFor}
                className="form-label">
                {label}
            </label>
            <input
                type={type}
                id={htmlFor}
                className="form-control"
            />
        </div>
    )
}

export default LandingInput