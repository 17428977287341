import React from 'react'
import { useEtablissement } from '../../hooks/useEtablissement';

const EtablissementProfilPage = () => {
    const { etablissement } = useEtablissement()
    console.log({ etablissement });
    return (
        <div>EtablissementProfilPage</div>
    )
}

export default EtablissementProfilPage