import { createSlice } from "@reduxjs/toolkit";

const offreReducer = createSlice({
    name: 'offres',
    initialState: { selectedOffre: {}, offreId: null },
    reducers: {
        setSelectedOffre: (state, { payload }) => {
            state.selectedOffre = payload
        },
        setOffreId: (state, { payload }) => {
            return {
                ...state,
                offreId: payload
            }
        },
        resetOffreId: (state, { payload }) => {
            return {
                ...state,
                offreId: null
            }
        },
        resetselectedOffre: (state) => {
            state.selectedOffre = {}
        }
    }
})

export const { setSelectedOffre, resetSelectedOffre, setOffreId, resetOffreId } = offreReducer.actions

export default offreReducer.reducer