import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { RiMenu3Fill } from 'react-icons/ri'
import LandingBtn from './LandingBtn'
import { LANDINGPAGE_OFFRES_PATH, LOGIN_PATH } from '../../../constants/routes_path'

const Header = () => {
    const navigate = useNavigate()
    const links = [
        {
            to: '',
            label: 'Accueil'
        },
        {
            to: LANDINGPAGE_OFFRES_PATH,
            label: 'Offres'
        },
        {
            to: '/etudiants',
            label: 'Etudiants'
        },
        {
            to: '/entreprises',
            label: 'Entreprises'
        },
        {
            to: '/etablissements',
            label: 'Etablissements'
        }
    ]

    return (
        <div className='header_container'>
            <div className='landing_page_container'>
                <nav className='navbar navbar-expand-lg p-0'>
                    <div className='logo-pane navbar-brand'>
                        <span className='logo-green'>Bakeli</span> <span className="logo-orange">Carriere</span>
                    </div>
                    <button style={{ border: 'none !important' }} class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <RiMenu3Fill />
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className='nav_items ms-auto d-flex flex-column flex-lg-row gap-4'>
                            {
                                links.map(link => {
                                    return <li className='nav_item'>
                                        <NavLink className="nav_link" to={link.to}>
                                            {link.label}
                                        </NavLink>
                                    </li>
                                })
                            }
                        </ul>
                        <LandingBtn
                            onClick={() => navigate(LOGIN_PATH)}
                            className='bg-color-green rounded ms-lg-2 ms-0 mt-3 mt-lg-0 py-1 fs-5'
                        >
                            Connexion
                        </LandingBtn>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Header